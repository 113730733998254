// Libraries, components, etc. //
import fileDownload from 'js-file-download';
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Button from '../button';
import Icon from '../../icons';
import Input from '../input';
import Textarea from '../textarea';
import * as vars from '../../constants';
import { v4 as uuidv4 } from 'uuid';

//#region Styles
const ModalComponent = styled.div`
    transition: ease-in .2s height;
    width: 60vw; 
    height: 100%;
    padding-bottom: 1rem;

    @media ${vars.DEVICE.mobileS} {
        width: 100vw;
        height: calc(100vh - ${vars.SIZE_HEADER_HEIGHT});
        overflow-y: auto;
    }

    @media ${vars.DEVICE.laptopS} {
        width: 100%;
        height: 100%;
    }
`;
const Header = styled.div`
    flex-direction: row;
    display: flex;
    border-bottom: 1px solid ${vars.COLOR_BORDER};
    width: 100%;
    height: 40px;
    text-align: center;
`;
const Title = styled.span`
    margin: auto 1rem;
    width: 100%;
    font-size: 1rem;
    text-align: center;
`;
const FormRow = styled.div`
    flex-direction: row;
    display: flex;

    @media ${vars.DEVICE.mobileS} {
        flex-direction: column;
    }

    @media ${vars.DEVICE.laptopS} {
        flex-direction: row;
    }
`;
const Form = styled.div`
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    display: ${props => props.hidden ? 'none' : 'flex'};
    padding: .5rem 1rem;
`;
const ButtonRow = styled.div`
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    display: flex;
`;
const SectionName = styled.span`
    display: inline-block;
    font-weight: 600;
    width: 100%;
    color: ${vars.COLOR_TEXT_MAIN};
`;
const HistoryItem = styled.span`
    display: inline-block;
    width: 100%;
    font-size: 0.75rem;
    color: ${vars.COLOR_TEXT_SECONDARY};
`;
const HistoryList = styled.div`
    flex-direction: column;
    display: flex;
`;
const DateRow = styled.div`
    flex-direction: row;
    gap: 1rem;
    display: flex;
    width: 100%;
`;
const DateStartButton = styled.span`
    border-radius: .25rem;
    padding: .25rem;
    background-color: ${props => props.hasDateStart ? vars.COLOR_SUCCESS : vars.COLOR_GRAY};
    cursor: pointer;
    font-size: .75rem;
    color: ${vars.COLOR_WHITE};
    white-space: nowrap;
`;
const FilesList = styled.div`
    flex-direction: row;
    flex-wrap: nowrap;
    gap: .25rem;
    display: flex;
    overflow-x: auto;
    border: 1px solid ${vars.COLOR_BORDER};
    border-radius: .25rem;
    width: calc(60vw / 2 - 2rem);
    padding: .5rem;

    @media ${vars.DEVICE.mobileS} {
        width: 100%;
    }

    @media ${vars.DEVICE.laptopS} {
        width: calc(60vw / 2 - 2rem);
    }
`;
const FileItem = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;
    border: 1px dashed ${vars.COLOR_BORDER};
    border-radius: .25rem;
    width: 100px;
    padding: .25rem;
`;
const FileName = styled.div`
    overflow: hidden;
    font-size: .75rem;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const FileType = styled.div`
    position: relative;
    margin: 0 auto;
    cursor: pointer;
`;
const FileImage = styled.img`
    width: 64px;
    height: auto;
`;
const FileUpload = styled.input`
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 64px;
    height: 64px;
    cursor: pointer;
`;
const HeaderButtons = styled.div`
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    display: flex;
    height: 40px;
    padding-right: 50px;
`;
const TimepickerButton = styled.button`
    margin: auto 0;
    border: 1px solid ${vars.COLOR_DANGER};
    height: 1.5rem;
    padding: .25rem .5rem;
    background-color: ${vars.COLOR_DANGER};
    cursor: pointer;
    font-size: .75rem;
    color: ${vars.COLOR_WHITE};
    outline: none;
`;
const WorkTime = styled.span`
    display: inline-block;
    margin-top: auto;
    border-top: 1px solid ${vars.COLOR_BORDER};
    width: 100%;
    padding-top: .25rem;
    font-size: 0.8rem;
`;
const TagInput = styled.input`
    border: none;
    border-bottom: 1px solid ${vars.COLOR_BORDER};
    outline: none;
`;
const TagInputContainer = styled.div`
    flex-direction: row;
    gap: .5rem;
    display: flex;
`;
const TagList = styled.div`
    flex-direction: row;
    gap: .5rem;
    display: flex;
`;
const Tag = styled.div`
    flex-direction: row;
    gap: .5rem;
    display: flex;
    border: 1px solid ${vars.COLOR_INFO};
    border-radius: .25rem;
    padding: .25rem .5rem;
    background-color: ${vars.COLOR_INFO};
`;
const TagText = styled.span`
    display: flex;
    margin: auto 0;
    cursor: ${props => props.isButton ? 'pointer' : 'default'};
    font-size: 0.8rem;
    color: ${vars.COLOR_WHITE};
`;
const SubtaskList = styled.div`
    flex-direction: column;
    gap: .25rem;
    display: flex;
    max-height: 200px;
    overflow-y: auto;
`;
const SubtaskItem = styled.div`
    flex-direction: row;
    gap: .25rem;
    display: flex;
    border: 1px solid ${props => props.isEdit ? vars.COLOR_INFO : props.isDone ? vars.COLOR_SUCCESS : vars.COLOR_BORDER};
    border-radius: .25rem;
    padding: .25rem .5rem;
`;
const SubtaskItemInput = styled.input`
    flex: 1;
    border: none;
    outline: none;
`;
const UndoneIcon = styled.div`
    content: " ";
    margin: auto 0;
    border: 1px solid ${vars.COLOR_TEXT_SECONDARY};
    border-radius: 50%;
    width: 16px;
    height: 16px;
`;

//#endregion

function ModalTask(props) {
    const [id,setId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [projectBlockId, setProjectBlockId] = useState('');
    const [isDone, setDone] = useState(false);
    const [startDueDate,setStartDueDate] = useState(null);
    const [startDueTime, setStartDueTime] = useState(null);
    const [endDueDate, setEndDueDate] = useState(null);
    const [endDueTime, setEndDueTime] = useState(null);
    const [hasDateStart, setHasDateStart] = useState(false);
    const [filePreviewId, setFilePreviewId] = useState(null);
    const [tagName, setTagName] = useState('');
    const [tagsTemp, setTagsTemp] = useState([]);
    const [subtaskId, setSubtaskId] = useState('');
    const [subtaskName, setSubtaskName] = useState('');
    const [subtaskAddName, setSubtaskAddName] = useState('');
    const [subtasksTemp, setSubtasksTemp] = useState([]);
    const [filesTemp, setFilesTemp] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

	const histories = (props.store.History.histories.filter(history => history.type === 'task' & history.object_id === id)).slice(0,5);
    const files = props.store.File.files.filter(file => file.task_id === id);
    const timepickers = props.store.Timepicker.timepickers.filter(timepicker => timepicker.task_id === id);
    let totalTime = 0;
    let lastTimepicker = null;
    timepickers.map(timepicker => {
        if (timepicker.end_datetime !== null) {
            totalTime += new Date(timepicker.end_datetime) - new Date(timepicker.start_datetime);
        }
        else {
            lastTimepicker = timepicker;
        }
    })
    const tags = props.store.Tag.tags.filter(tag => tag.task_id === id);
    const subtasks = props.store.Subtask.subtasks.filter(subtask => subtask.task_id === id);

    useEffect(() => {
        setProjectBlockId(props.data.project_block_id);

        if (props.data.id != '') {
            setId(props.data.id);
            setName(props.data.name);
            setDescription(props.data.description);    
            setDone(props.data.is_done);
            setStartDueDate(props.data.start_due_date);
            setStartDueTime(props.data.start_due_time);
            setEndDueDate(props.data.end_due_date);
            setEndDueTime(props.data.end_due_time);
            setFilePreviewId(props.data.file_preview_id);
            setHasDateStart(props.data.start_due_date !== null);
        }
    }, [props.data]);

    function _onPaste(event) {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        if (items.length > 0) {
            const item = items[0];
            if (item.kind === 'file') {
                fileFunction('add', item.getAsFile());
            }
        }
    }

    function setCover(_id) {
        setFilePreviewId(filePreviewId === _id ? null : _id);
    }

    function setStartDateState(_state) {
        this.setState({
            hasDateStart: _state,
            startDueDate: null,
            startDueTime: null
        })
    }

    function fileFunction(_type = 'none', _file) {
        if (_type === 'none')
            return;

        switch (_type) {
            case 'add':
                if (id === '') {
                    _file.id = uuidv4();
                    filesTemp.push(_file);
                    setFilesTemp(filesTemp);
                }
                else {
                    const data = {
                        id: uuidv4(),
                        task_id: id,
                        file: _file
                    };

                    props.store.File.upload(data);
                }
                break;
            case 'delete':
                if (id === '') {
                    filesTemp.splice(filesTemp.indexOf(_file), 1);
                    setFilesTemp(filesTemp);
                }
                else {
                    props.store.File.delete(_file.id);
                }
                break;
            default:
                break;
        }
        
    }

    function subtaskFunction(_type = 'none', _data) {
        if (_type === 'none')
            return;

        let data = null;
        switch (_type) {
            case 'edit':
                setSubtaskId(_data.id);
                setSubtaskName(_data.name);
                break;
            case 'add':
                data = {
                    id: -1,
                    name: subtaskAddName,
                    is_done: false,
                    task_id: id,
                    done_datetime: null
                }

                if (id === '') {
                    subtasksTemp.push(data);
                    setSubtaskId('');
                    setSubtaskAddName('');
                    setSubtasksTemp(subtasksTemp);
                }
                else {
                    props.store.Subtask.create(data);
                    setSubtaskId('');
                    setSubtaskAddName('');
                }
                break;
            case 'update':
                data = {
                    id: _data.id,
                    name: subtaskName,
                    is_done: _data.is_done,
                    done_datetime: _data.done_datetime
                }
                
                if (id === '') {
                    subtasksTemp[subtasksTemp.indexOf(_data)] = data;
                    setSubtaskId('');
                    setSubtaskAddName('');
                    setSubtasksTemp(subtasksTemp);
                }
                else {
                    props.store.Subtask.update(data);
                    setSubtaskId('');
                    setSubtaskName('');
                }
                break;
            case 'done':
                data = {
                    id: _data.id,
                    name: _data.name,
                    is_done: !_data.is_done,
                    done_datetime: !_data.is_done ? new Date().toISOString() : null
                }

                if (id === '') {
                    subtasksTemp[subtasksTemp.indexOf(_data)] = data;
                    setSubtaskId('');
                    setSubtaskAddName('');
                    setSubtasksTemp(subtasksTemp);
                }
                else {
                    props.store.Subtask.update(data);
                    setSubtaskId('');
                    setSubtaskName('');
                }
                break;
            case 'delete':
                if (id === '') {
                    subtasksTemp.splice(subtasksTemp.indexOf(_data), 1);
                    setSubtaskId('');
                    setSubtaskAddName('');
                    setSubtasksTemp(subtasksTemp);
                }
                else {
                    props.store.Subtask.delete(_data.id);
                    setSubtaskId('');
                    setSubtaskName('');
                }
                
                break;
            default:
                break;
        }
    }

    function startStopTimepicker(_timepicker) {
        let data = null;

        if (_timepicker === null) {
            data = {
                task_id: id,
                start_datetime: new Date().toISOString()
            }

            props.store.Timepicker.create(data);
        }
        else {
            data = {
                id: _timepicker.id,
                task_id: id,
                start_datetime: _timepicker.start_datetime,
                end_datetime: new Date().toISOString()
            }

            props.store.Timepicker.update(data);
        }
    }

    function tagFunction(type = 'none', _id) {
        if (type === 'none')
            return;

        const data = {
            id: _id,
            task_id: id,
            name: tagName
        }

        switch (type) {
            case 'add':
                if (id === '') {
                    tagsTemp.push(data);
                    setTagName('');
                    setTagsTemp(tagsTemp);
                }
                else {
                    props.store.Tag.create(data);
                    setTagName('');
                }
                break;
            case 'delete':
                if (id === '') {
                    tagsTemp.splice(_id, 1);
                    setTagName('');
                    setTagsTemp(tagsTemp);
                }
                else {
                    props.store.Tag.delete(_id);
                }
                break;
            default:
                break;
        }
    }

    function taskFunction(type = 'none', _files) {
        if (type === 'none')
            return;

        const data = {
            id: id,
            name: name,
            description: description,
            project_block_id: projectBlockId,
            is_done: isDone,
            start_due_date: startDueDate,
            start_due_time: startDueTime,
            end_due_date: endDueDate,
            end_due_time: endDueTime,
            file_preview_id: filePreviewId
        }
        let hideModal = true;

        switch (type) {
            case 'add':
                props.store.Task.create(data, subtasksTemp, tagsTemp, filesTemp, props.store);
                break;
            case 'update':
                props.store.Task.update(data);
                break;
            case 'delete':
                _files.map(file => {
                    props.store.File.delete(file.id);
                })
                props.store.Task.delete(data.id, _files);
                break;
            case 'set_cover':
                props.store.Task.update(data);
                hideModal = false;
                break;
            default:
                break;
        }

        if (hideModal)
            props.store.Main.hideModal();
    }

    return (
        <ModalComponent  onPaste={(event) => _onPaste(event)}>
            <Header>
                <Title>{id === '' ? 'Добавить задачу' : 'Изменить задачу'}</Title>
                <HeaderButtons>
                    {
                        id !== '' ?
                            <TimepickerButton onClick={() => startStopTimepicker(lastTimepicker)}>{lastTimepicker === null ? 'Начать' : 'Завершить'}</TimepickerButton> : null
                    }
                    <Button type='close' onClick={() => props.store.Main.hideModal()}/>
                </HeaderButtons>
            </Header>
            <FormRow>
                <Form> 
                    <Input type='text' label='Название' value={name} onChange={(event) => setName(event.target.value)}/>
                    <Textarea type='text' label='Описание' value={description} onChange={(event) => setDescription(event.target.value)}/>
                    <DateRow>
                        <SectionName>Срок выполнения</SectionName>
                        <DateStartButton hasDateStart={hasDateStart} onClick={() => setStartDateState(!hasDateStart)}>Дата начала</DateStartButton>
                    </DateRow>
                    {
                        hasDateStart ?
                            <DateRow>
                                <Input type='date' label='Дата начала' value={startDueDate} onChange={(event) => setStartDueDate(event.target.value)}/>
                                <Input type='time' label='Время' value={startDueTime} onChange={(event) => setStartDueTime(event.target.value)}/>
                            </DateRow> : null
                    }
                    <DateRow>
                        <Input type='date' label='Дата завершения' value={endDueDate} onChange={(event) => setEndDueDate(event.target.value)}/>
                        <Input type='time' label='Время' value={endDueTime} onChange={(event) => setEndDueTime(event.target.value)}/>
                    </DateRow>
                    <SectionName>Подзадачи</SectionName>
                    <TagInputContainer>
                        <TagInput type='text' placeholder='Добавить позадачу' value={subtaskAddName} onChange={(event) => setSubtaskAddName(event.target.value)}/>
                        <Icon name='add' width='16px' hoverColor={vars.COLOR_SUCCESS} onClick={() => subtaskFunction('add', null)}/>
                    </TagInputContainer>
                    <SubtaskList>
                    {
                        id === '' ?
                        subtasksTemp.map(subtask => 
                            <SubtaskItem isEdit={subtaskId === subtask.id} isDone={subtask.is_done}>
                                <SubtaskItemInput type='text' id='subtaskName' readOnly={subtaskId !== subtask.id} value={subtaskId !== subtask.id ? subtask.name : subtaskName} onChange={(event) => setSubtaskName(event.target.value)}/>
                                {
                                    subtask.is_done ?
                                        <Icon name='done' width='16px' onClick={() => subtaskFunction('done', subtask)}/> :
                                        <UndoneIcon onClick={() => subtaskFunction('done', subtask)}/>
                                }
                                <Icon onClick={() => subtaskFunction(subtaskId !== subtask.id ? 'edit' : 'update', subtask)} name={subtaskId !== subtask.id ? 'edit' : 'save'} width='16px' color={subtaskId !== subtask.id ? vars.COLOR_TEXT_SECONDARY : vars.COLOR_PRIMARY} hoverColor={vars.COLOR_PRIMARY}/>
                                <Icon onClick={() => subtaskFunction('delete', subtask)} name='delete' width='16px' color={vars.COLOR_TEXT_SECONDARY} hoverColor={vars.COLOR_DANGER}/>
                            </SubtaskItem>
                        ) :
                        subtasks.map(subtask => 
                            <SubtaskItem isEdit={subtaskId === subtask.id} isDone={subtask.is_done}>
                                <SubtaskItemInput type='text' id='subtaskName' readOnly={subtaskId !== subtask.id} value={subtaskId !== subtask.id ? subtask.name : subtaskName} onChange={(event) => setSubtaskName(event.target.value)}/>
                                {
                                    subtask.is_done ?
                                        <Icon name='done' width='16px' onClick={() => subtaskFunction('done', subtask)}/> :
                                        <UndoneIcon onClick={() => subtaskFunction('done', subtask)}/>
                                }
                                <Icon onClick={() => subtaskFunction(subtaskId !== subtask.id ? 'edit' : 'update', subtask)} name={subtaskId !== subtask.id ? 'edit' : 'save'} width='16px' color={subtaskId !== subtask.id ? vars.COLOR_TEXT_SECONDARY : vars.COLOR_PRIMARY} hoverColor={vars.COLOR_PRIMARY}/>
                                <Icon onClick={() => subtaskFunction('delete', subtask)} name='delete' width='16px' color={vars.COLOR_TEXT_SECONDARY} hoverColor={vars.COLOR_DANGER}/>
                            </SubtaskItem>
                        )
                    }
                    </SubtaskList>
                </Form>
                <Form>
                    <DateRow>
                        <SectionName>Теги</SectionName>
                    </DateRow>
                    <TagList>
                    {
                        id === '' ?
                        tagsTemp.map((tag, index) => 
                            <Tag>
                                <TagText>{tag.name}</TagText>
                                <TagText isButton={true} onClick={() => tagFunction('delete', index)}>x</TagText>
                            </Tag>    
                        ) :
                        tags.map(tag => 
                            <Tag>
                                <TagText>{tag.name}</TagText>
                                <TagText isButton={true} onClick={() => tagFunction('delete', tag.id)}>x</TagText>
                            </Tag>    
                        )
                    }
                    </TagList>
                    <TagInputContainer>
                        <TagInput type='text' id='tagName' placeholder='Добавить тег' value={tagName} onChange={(event) => setTagName(event.target.value)}/>
                        <Icon name='add' width='16px' hoverColor={vars.COLOR_SUCCESS} onClick={() => tagFunction('add')}/>
                    </TagInputContainer>
                    <SectionName>Файлы</SectionName>
                    <FilesList>
                    {
                        id === '' ?
                        filesTemp.map(file => 
                            <FileItem>
                                <FileType onClick={() => fileDownload(file.url, file.name)}>
                                {
                                    file.type.includes('image') ?
                                        <FileImage src={URL.createObjectURL(file)}/>  :
                                        <Icon name='file' width='48px' color={vars.COLOR_TEXT_SECONDARY}/>
                                }
                                </FileType>
                                <FileName>{file.name}</FileName>
                                <ButtonRow>
                                    {
                                        file.type.includes('image') ?
                                            <Icon name='preview' width='18px' onClick={() => setCover(file.id)} color={filePreviewId === file.id ? vars.COLOR_SUCCESS : vars.COLOR_TEXT_MAIN} hoverColor={filePreviewId === file.id ? vars.COLOR_TEXT_MAIN : vars.COLOR_SUCCESS}/> : null
                                    }
                                    <Icon name='delete' width='18px' hoverColor={vars.COLOR_DANGER} onClick={() => fileFunction('delete', file)}/>
                                </ButtonRow>
                            </FileItem>
                        ) :
                        files.map(file => 
                            <FileItem>
                                <FileType onClick={() => fileDownload(file.url, file.name)}>
                                {
                                    file.type.includes('image') ?
                                        <FileImage src={file.url}/> :
                                        <Icon name='file' width='48px' color={vars.COLOR_TEXT_SECONDARY}/>
                                }
                                </FileType>
                                <FileName>{file.name}</FileName>
                                <ButtonRow>
                                    {
                                        file.type.includes('image') ?
                                            <Icon name='preview' width='18px' onClick={() => setCover(file.id)} color={filePreviewId === file.id ? vars.COLOR_SUCCESS : vars.COLOR_TEXT_MAIN} hoverColor={filePreviewId === file.id ? vars.COLOR_TEXT_MAIN : vars.COLOR_SUCCESS}/> : null
                                    }
                                    <Icon name='delete' width='18px' hoverColor={vars.COLOR_DANGER} onClick={() => fileFunction('delete', file)}/>
                                </ButtonRow>
                            </FileItem>
                        )
                    }
                        <FileItem>
                            <FileType>
                                <Icon name='file' width='48px' color={vars.COLOR_TEXT_SECONDARY}/>
                                <FileUpload type='file' id='selectedFile' value={selectedFile} onChange={(event) => fileFunction('add',event.target.files[0])}/>
                            </FileType>
                            <FileName>Прикрепить</FileName>
                        </FileItem>
                    </FilesList>
                    {
                        id !== 0 ?
                            <HistoryList>
                            <SectionName>Последние действия</SectionName>
                            {
                                histories.map((history, index) =>
                                    <HistoryItem key={index}>{history.history_type.name_ru+' '+new Date(history.history_datetime).toLocaleDateString()}</HistoryItem>)
                            }
                            </HistoryList> : null
                    }
                    {
                        id !== 0 ?
                            <WorkTime>Потраченное время: {new Date(totalTime).toISOString().substr(11,8)}</WorkTime> : null
                    }
                </Form>
            </FormRow>
            <ButtonRow>
                {
                    id !== '' ?
                        <Button type='danger' size='0.9rem' onClick={() => taskFunction('delete', files)}>Удалить</Button> : null
                }
                <Button type='success' size='0.9rem' onClick={() => taskFunction(id === '' ? 'add' : 'update')}>{id === '' ? 'Добавить' : 'Сохранить'}</Button>
            </ButtonRow>
        </ModalComponent>
    );
}

export default inject("store")(observer(ModalTask)); 
