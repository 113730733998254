// Libraries, components, etc. //
import axios from 'axios';
import { action, makeObservable, observable } from "mobx";
import { toast } from 'react-toastify';
import { config } from '../env';
import { getToken } from '../utils';

const NODE_ENV = process.env.NODE_ENV || 'production';

class Timepicker {
    token = '';
    timepickers = [];

    constructor() {
        makeObservable(this, {
            token: observable,
            timepickers: observable,
            create: action,
            update: action,
            delete: action
        });

        this.token = getToken();
        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'andtask';
        axios.defaults.headers.common['x-access-token'] = this.token;
        this.loadData();

        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
    }

    loadData() {
        axios.get(config.api.host_andtask[NODE_ENV]+config.api.timepicker.getall)
        .then(result => {
            if (result.data.status) {
                this.timepickers = result.data.data;
            }
            else {
                toast.error(result.data.text);
            }
        })
    }

    create(_data) {
        const data = {
            start_datetime: _data.start_datetime,
            task_id: _data.task_id,
        };

        axios.post(config.api.host_andtask[NODE_ENV] + config.api.timepicker.create, data)
        .then(result => {
            if (result.data.status) {
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    update(_data) {
        const data = {
            id: _data.id,
            start_datetime: _data.start_datetime,
            end_datetime: _data.end_datetime
        };

        axios.put(config.api.host_andtask[NODE_ENV] + config.api.timepicker.update, data)
        .then(result => {
            if (result.data.status) {
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    delete(_id) {
        axios.delete(config.api.host_andtask[NODE_ENV] + config.api.timepicker.delete + _id)
        .then(result => {
            if (result.data.status) {
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }
}

export default new Timepicker();