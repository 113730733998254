import SVG from '../components/icon';

export default function FileDOC (props) {
    return (
        <SVG
            width={props.width}
            height={props.height === undefined || props.height === null ? props.width : props.height}
            style={{margin: 'auto 0'}}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            {...props}
        >
			<g>
			<g>
			<path d="M494.479,138.557L364.04,3.018C362.183,1.09,359.621,0,356.945,0h-194.41c-21.757,0-39.458,17.694-39.458,39.442v137.789
			H44.29c-16.278,0-29.521,13.239-29.521,29.513v147.744C14.769,370.761,28.012,384,44.29,384h78.787v88.627
			c0,21.71,17.701,39.373,39.458,39.373h295.238c21.757,0,39.458-17.653,39.458-39.351V145.385
			C497.231,142.839,496.244,140.392,494.479,138.557z M359.385,26.581l107.079,111.265H359.385V26.581z M44.29,364.308
			c-5.42,0-9.828-4.405-9.828-9.82V206.744c0-5.415,4.409-9.821,9.828-9.821h265.881c5.419,0,9.829,4.406,9.829,9.821v147.744
			c0,5.415-4.41,9.82-9.829,9.82H44.29z M477.538,472.649c0,10.84-8.867,19.659-19.766,19.659H162.535
			c-10.899,0-19.766-8.828-19.766-19.68V384h167.401c16.279,0,29.522-13.239,29.522-29.512V206.744
			c0-16.274-13.243-29.513-29.522-29.513H142.769V39.442c0-10.891,8.867-19.75,19.766-19.75h177.157v128
			c0,5.438,4.409,9.846,9.846,9.846h128V472.649z"/>
			</g>
			</g>
			<g>
			<g>
			<path d="M130.826,263.451c-1.846-5.403-4.536-9.969-8.076-13.701c-3.537-3.731-7.788-6.326-12.75-7.788
			c-3.692-1.079-9.056-1.615-16.096-1.615h-31.21v84.576h32.133c6.309,0,11.348-0.595,15.116-1.79c5.039-1.615,9.039-3.865,12-6.749
			c3.924-3.808,6.942-8.788,9.059-14.942c1.73-5.039,2.594-11.037,2.594-18.001C133.595,275.52,132.672,268.856,130.826,263.451z
			M114.21,298.183c-1.152,3.789-2.644,6.508-4.47,8.162c-1.827,1.654-4.126,2.829-6.895,3.52
			c-2.114,0.539-5.558,0.807-10.326,0.807H79.769v-56.02h7.672c6.964,0,11.636,0.269,14.021,0.808
			c3.193,0.694,5.827,2.021,7.904,3.98c2.075,1.962,3.692,4.694,4.844,8.195c1.154,3.498,1.733,8.517,1.733,15.057
			C115.944,289.231,115.365,294.395,114.21,298.183z"/>
			</g>
			</g>
			<g>
			<g>
			<path d="M294.097,293.826c-1.425,6.193-3.771,10.733-7.04,13.615c-3.269,2.885-7.173,4.328-11.712,4.328
			c-6.154,0-11.153-2.27-14.998-6.806c-3.847-4.539-5.77-12.156-5.77-22.848c0-10.075,1.952-17.364,5.856-21.863
			c3.904-4.502,8.99-6.753,15.259-6.753c4.539,0,8.394,1.27,11.567,3.808c3.173,2.54,5.261,6.001,6.26,10.385l16.904-4.04
			c-1.922-6.769-4.807-11.96-8.652-15.576c-6.462-6.114-14.865-9.172-25.211-9.172c-11.848,0-21.406,3.894-28.674,11.683
			c-7.269,7.789-10.905,18.72-10.905,32.797c0,13.307,3.616,23.798,10.848,31.471c7.229,7.673,16.46,11.51,27.692,11.51
			c9.075,0,16.566-2.24,22.471-6.722c5.903-4.48,10.124-11.335,12.662-20.566L294.097,293.826z"/>
			</g>
			</g>
			<g>
			<g>
			<path d="M215.434,250.501c-7.483-7.732-17.452-11.597-29.915-11.597c-6.885,0-12.96,1.115-18.23,3.345
			c-3.961,1.654-7.606,4.192-10.932,7.616c-3.328,3.421-5.952,7.288-7.877,11.596c-2.577,5.846-3.864,13.076-3.864,21.691
			c0,13.462,3.712,24.03,11.133,31.702c7.424,7.673,17.426,11.51,30.002,11.51c12.423,0,22.346-3.854,29.77-11.567
			c7.421-7.712,11.133-18.395,11.133-32.049C226.654,268.979,222.914,258.23,215.434,250.501z M202.452,304.414
			c-4.364,4.903-9.952,7.355-16.761,7.355c-6.806,0-12.421-2.471-16.844-7.412c-4.423-4.943-6.636-12.202-6.636-21.779
			c0-9.731,2.154-17.01,6.461-21.837c4.308-4.827,9.982-7.242,17.019-7.242c7.04,0,12.684,2.385,16.933,7.156
			c4.251,4.768,6.375,12,6.375,21.691C208.999,292.155,206.818,299.51,202.452,304.414z"/>
			</g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
        </SVG>
    );
}