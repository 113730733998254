// Colors //
export const COLOR_BORDER = '#cccccc';
export const COLOR_BACKGROUND = '#f5f4f9';
export const COLOR_TEXT_MAIN = '#131516';
export const COLOR_TEXT_SECONDARY = '#6c6c6c';
export const COLOR_PRIMARY = '#27E1E8';
export const COLOR_SECONDARY = '#a34a7d';
export const COLOR_SUCCESS = '#34B54E';
export const COLOR_INFO = '#1670F7';
export const COLOR_WARNING = '#FFE70F';
export const COLOR_DANGER = '#FF3A4B';
export const COLOR_WHITE = 'white';
export const COLOR_GRAY = '#cccccc';

export const COLORS = {
    BORDER: '#cccccc',
    BACKGROUND: '#f5f4f9',
    TEXT_MAIN: '#131516',
    TEXT_SECONDARY: '#6c6c6c',
    PRIMARY: '#27E1E8',
    SECONDARY: '#a34a7d',
    SUCCESS: '#34B54E',
    INFO: '#1670F7',
    WARNING: '#FFE70F',
    DANGER: '#FF3A4B',
    WHITE: 'white',
    GRAY: '#cccccc',
}

export const gradient = 'linear-gradient(-45deg, rgba(238, 119, 82, 1), rgb(64%, 29%, 49%, 1), rgb(14%, 65%, 84%, 1), rgb(14%, 84%, 67%, 1))';

// Sizes //
export const SIZE_HEADER_HEIGHT = '50px';
export const BREAKPOINTS = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptopS: '1024px',
    laptopM: '1200px',
    laptopL: '1440px',
    desktop: '2560px'
};
export const DEVICE = {
    mobileS: `(min-width: ${BREAKPOINTS.mobileS})`,
    mobileM: `(min-width: ${BREAKPOINTS.mobileM})`,
    mobileL: `(min-width: ${BREAKPOINTS.mobileL})`,
    tablet: `(min-width: ${BREAKPOINTS.tablet})`,
    laptopS: `(min-width: ${BREAKPOINTS.laptopS})`,
    laptopM: `(min-width: ${BREAKPOINTS.laptopM})`,
    laptopL: `(min-width: ${BREAKPOINTS.laptopL})`,
    desktop: `(min-width: ${BREAKPOINTS.desktop})`
  };