// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import React, { useState } from 'react';
import { Droppable, Draggable   } from 'react-beautiful-dnd';
import styled from 'styled-components';
import Button from '../../components/button';
import Icon from '../../icons';
import * as vars from '../../constants';

//#region Styles
const Component = styled.div`
    position: relative; 
    flex-direction: column;
    gap: .5rem;
    display: flex;
    width: 300px;
`;
const Header = styled.div`
    flex-direction: row;
    gap: .5rem;
    display: flex;
    border-left: 2px solid ${vars.COLOR_BORDER};
`;
const Name = styled.span`
    flex: 1;
    margin: auto 0.5rem;
    cursor: pointer;
    font-weight: 600;
`;
const TaskItem = styled.div`
    position: relative;
    flex-direction: column;
    display: flex;
    transition: ease-in 0.2s border;
    box-shadow: rgb(0 0 0 / 57%) 0px 3px 10px -8px;
    border: 1px solid ${props => props.isDone ? vars.COLOR_SUCCESS : vars.COLOR_BORDER};
    border-radius: .25rem;
    //padding: .25rem .5rem;
    background-color: ${vars.COLOR_WHITE};
    cursor: pointer !important;

    &:hover {
        border: 1px solid ${vars.COLOR_PRIMARY};
    }
`;
const TaskPreview = styled.img`
    //margin: .25rem auto;
    width: 100%;
    border-radius: .25rem;
    height: auto;
`;
const TaskName = styled.div`
    flex-direction: row;
    gap: .25rem;
    width: 100%;
    display: flex;
    padding: .25rem;
`;
const UndoneIcon = styled.div`
    content: " ";
    margin: auto 0;
    border: 1px solid ${vars.COLOR_TEXT_SECONDARY};
    border-radius: 50%;
    width: 18px;
    height: 18px;
`;
const TaskList = styled.div`
    position: relative; 
    flex-direction: column;
    gap: .5rem;
    display: flex;
    overflow-y: auto;
    max-height: calc(100vh - ${vars.SIZE_HEADER_HEIGHT} * 4 - 2rem);
    min-height: 50px;
`;
const TaskCount = styled.span`
    color: ${vars.COLOR_TEXT_SECONDARY};
`;
const TaskNameTitle = styled.span`
    flex: 1;
`;
const DoneButton = styled.button`
    position: absolute;
    left: 25%;
    bottom: 1rem;
    transition: color .3s ease-in-out;
    margin: auto 0;
    width: 50%;
    padding: .5rem;
    background: ${props => props.isDone ? vars.gradient : 'white'};
    outline: none;
    border: 1px solid ${vars.COLORS.BORDER};;
    border-radius: .25rem;
    color: ${props => props.isDone ? 'white' : vars.COLORS.TEXT_SECONDARY};
    cursor: pointer;

    &:hover {
        color: ${props => props.isDone ? 'white' : vars.COLORS.SUCCESS};
    }
`;
//#endregion

function ProjectBlock(props) {
    const [isFocused, setFocused] = useState(false);
    const projectBlock = props.data;
    const tasks = props.store.Task.tasks.filter(task => task.project_block_id === projectBlock.id);

    function setHideState(_projectBlock) {
        const data = {
            id: _projectBlock.id,
            name: _projectBlock.name,
            description: _projectBlock.description,
            is_hide_done: !_projectBlock.is_hide_done
        }

        props.store.ProjectBlock.update(data);
    }

    function setTaskDoneState(_task) {
        const data = {
            ..._task
        }
        data.is_done = !data.is_done;

        props.store.Task.update(data);
    }

    return(
        <Component>
            <Header>
                <Name onClick={() => props.store.Main.setModal('project_block', projectBlock)}>{projectBlock.name}</Name>
                <Icon name={projectBlock.is_hide_done ? 'hide' : 'show'} width='16px' color={vars.COLOR_TEXT_SECONDARY} onClick={() => setHideState(projectBlock)}/>
                <TaskCount>{tasks.filter(task => (projectBlock.is_hide_done === true && !task.is_done) || projectBlock.is_hide_done === false).length}</TaskCount>
            </Header>
            <Button onClick={() => props.store.Main.setModal('task', {id: '', project_block_id: projectBlock.id})}>Добавить</Button>
            <Droppable droppableId={projectBlock.id} type="task">
            {(provided, snapshot) => (
                <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                {
                    tasks.filter(task => (projectBlock.is_hide_done === true && !task.is_done) || projectBlock.is_hide_done === false).map((task,index) => 
                        <Draggable key={task.id} draggableId={task.id} index={index}>
                        {(provided1, snapshot) => (
                            <TaskItem
                                isDone={task.is_done}
                                
                                ref={provided1.innerRef}
                                {...provided1.draggableProps}
                                {...provided1.dragHandleProps}
                            >
                            {
                                task.file_preview_id !== null && task.file_preview_id !== null && task.filepreview != null ?
                                    <TaskPreview src={task.filepreview.url} onClick={() => props.store.Main.setModal('task', task)}/> : null
                            }
                            {
                                task.file_preview_id !== null && task.file_preview_id !== 0 && !task.name && <DoneButton isDone={task.is_done}  onClick={() => setTaskDoneState(task)}>{task.is_done ? 'Выполнено' : 'Выполнить'}</DoneButton>
                            }

                            {
                                task.name && <TaskName>
                                {
                                    task.is_done ?
                                        <Icon name='done' width='18px' onClick={() => setTaskDoneState(task)}/> :
                                        <UndoneIcon onClick={() => setTaskDoneState(task)}/>
                                }
                                    <TaskNameTitle onClick={() => props.store.Main.setModal('task', task)}>{task.name}</TaskNameTitle>
                                </TaskName>
                            }
                                
                            </TaskItem> 
                        )}
                        </Draggable>
                    )
                }
                {provided.placeholder}
                </TaskList>
            )}	
            </Droppable>
        </Component>
    )
}

export default inject("store")(observer(ProjectBlock));