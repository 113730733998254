import SVG from '../components/icon';

export default function FilePPT (props) {
    return (
        <SVG
            width={props.width}
            height={props.height === undefined || props.height === null ? props.width : props.height}
            style={{margin: 'auto 0'}}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            {...props}
        >
			<g>
			<g>
			<path d="M494.478,138.557L364.038,3.018C362.183,1.09,359.623,0,356.945,0H162.534c-21.757,0-39.457,17.694-39.457,39.442v137.789
			H44.289c-16.276,0-29.519,13.239-29.519,29.513v147.744c0,16.273,13.243,29.512,29.519,29.512h78.788v88.627
			c0,21.71,17.699,39.373,39.457,39.373h295.24c21.757,0,39.457-17.653,39.457-39.351V145.385
			C497.231,142.839,496.245,140.392,494.478,138.557z M359.385,26.581l107.079,111.265H359.385V26.581z M44.289,364.308
			c-5.418,0-9.827-4.405-9.827-9.82V206.744c0-5.415,4.409-9.821,9.827-9.821h265.882c5.421,0,9.829,4.406,9.829,9.821v147.744
			c0,5.415-4.409,9.82-9.829,9.82H44.289z M477.538,472.649c0,10.84-8.865,19.659-19.764,19.659h-295.24
			c-10.899,0-19.764-8.828-19.764-19.68V384h167.401c16.279,0,29.522-13.239,29.522-29.512V206.744
			c0-16.274-13.243-29.513-29.522-29.513H142.769V39.442c0-10.891,8.865-19.75,19.764-19.75h177.159v128
			c0,5.438,4.409,9.846,9.846,9.846h128V472.649z"/>
			</g>
			</g>
			<g>
			<g>
			<polygon points="224.153,240.347 224.153,254.653 249.251,254.653 249.251,324.923 266.326,324.923 266.326,254.653 
			291.365,254.653 291.365,240.347 		"/>
			</g>
			</g>
			<g>
			<g>
			<path d="M211.289,249.895c-3.269-4.251-7.328-7.01-12.172-8.281c-3.156-0.847-9.925-1.267-20.308-1.267h-27.405v84.576h17.076
			v-31.904h11.136c7.729,0,13.635-0.404,17.711-1.211c3.001-0.655,5.952-1.992,8.856-4.01c2.902-2.019,5.297-4.798,7.183-8.337
			c1.883-3.537,2.826-7.904,2.826-13.096C216.192,259.636,214.558,254.144,211.289,249.895z M196.665,273.174
			c-1.29,1.883-3.07,3.269-5.337,4.153c-2.27,0.883-6.769,1.326-13.502,1.326h-9.346v-24h8.251c6.154,0,10.249,0.192,12.288,0.576
			c2.769,0.502,5.058,1.75,6.865,3.751c1.81,1.999,2.713,4.539,2.713,7.616C198.597,269.095,197.952,271.288,196.665,273.174z"/>
			</g>
			</g>
			<g>
			<g>
			<path d="M132.48,249.895c-3.269-4.251-7.326-7.01-12.173-8.281c-3.153-0.847-9.922-1.267-20.308-1.267H72.595v84.576h17.078
			v-31.904h11.133c7.732,0,13.635-0.404,17.711-1.211c3.001-0.655,5.952-1.992,8.857-4.01c2.904-2.019,5.297-4.798,7.183-8.337
			c1.885-3.537,2.826-7.904,2.826-13.096C137.383,259.636,135.749,254.144,132.48,249.895z M117.856,273.174
			c-1.29,1.883-3.067,3.269-5.337,4.153c-2.269,0.883-6.769,1.326-13.499,1.326h-9.347v-24h8.249c6.154,0,10.25,0.192,12.288,0.576
			c2.769,0.502,5.058,1.75,6.865,3.751c1.81,1.999,2.713,4.539,2.713,7.616C119.789,269.096,119.144,271.288,117.856,273.174z"/>
			</g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
        </SVG>
    );
}