// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import React, { useState, useEffect } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Avatar from './avatar';
import Icon from '../icons';
import * as vars from '../constants';
import Logo from "../icons/logo";

//#region Styles
const HeaderComponent = styled.div`
    position: sticky;
	top: 0;
	z-index: 40;
    flex-direction: row;
	display: flex;
    border-bottom: 1px solid ${vars.COLOR_BORDER};
	width: 100vw;
	height: ${vars.SIZE_HEADER_HEIGHT};
    background-color: ${vars.COLOR_WHITE};
    box-shadow: rgb(0 0 0 / 57%) 0px 3px 10px -8px;
`;
const HeaderTitle = styled.div`
    flex: 1;
	flex-direction: row;
	justify-content: center;
	display: flex;
    margin: auto 0;
    height: 100%;
    text-align: center;
    color: ${vars.COLOR_TEXT_SECONDARY};
`;
const AnimatedLogo = styled.div`
	opacity: ${props => props.visible ? '0' : '1'};
	transition: ease-in 0.2s opacity;
`;
const NamespaceSection = styled.div`
	flex: 1;
	flex-direction: row;
	justify-content: center;
	gap: 0.25rem;
	display: flex;
`;
const NamespaceName = styled.span`
	margin: auto 0;
	font-size: larger;
	color: ${vars.COLOR_TEXT_MAIN};
`;
const UserSection = styled.div`
	position: absolute;
	top: 0;
	right: 1rem;
	flex-direction: row;
	gap: .5rem;
	display: flex;
	height: ${vars.SIZE_HEADER_HEIGHT};
`;
const UserInfo = styled.div`
	flex-direction: column;
	justify-content: center;
	display: flex;

	@media ${vars.DEVICE.mobileS} {
		display: none;
	}

	@media ${vars.DEVICE.laptopS} {
		display: flex;
	}
`;
const UserEmail = styled.span`
	font-size: .75rem;
	color: ${vars.COLOR_TEXT_SECONDARY};
`;
const UserMenu = styled.div`
	position: absolute;
	top: calc(${vars.SIZE_HEADER_HEIGHT} + .5rem);
	flex-direction: column;
	transition: ease-in .2s visibility, ease-in .2s opacity;
	display: flex;
	border: 1px solid ${vars.COLOR_BORDER};
	border-radius: .25rem;
	width: 100%;
	background-color: ${vars.COLOR_WHITE};
	opacity: ${props => props.visible ? '1' : '0'};
	visibility: ${props => props.visible ? 'visible' : 'hidden'};

	@media ${vars.DEVICE.mobileS} {
		right: 0;
		width: 50vw;
	}

	@media ${vars.DEVICE.laptopS} {
		width: 100%;
	}
`;
const UserMenuItem = styled.span`
	border-top: ${props => props.border ? '1px solid '+vars.COLOR_BORDER : 'none'};
	width: 100%;
	padding: .5rem;
	text-align: center;
	cursor: pointer;

	&:hover {
		background-color: ${vars.COLOR_BORDER};
		color: ${vars.COLOR_WHITE};
	}
`;
//#endregion

function Header(props)  {
	const { id } = useParams();
    const [projects, setProjects] = useState([]);
	const [namespace, setNamespace] = useState({});

    useEffect(() => {
		let ns = props.store.Namespace.namespaces.find(n => n.id === id);
		if (ns !== null) {
			setNamespace(ns);
		}
        setProjects(toJS(props.store.Project.projects).filter(project => project.namespace_id === id));
    }, [id, props.store.Project.projects, props.store.Namespace.namespaces])

	const [menuVisible, setMenuVisible] = useState(false);
	
	const sidebarVisible = props.store.Main.sidebarVisible;
	const userData = props.store.User.userData;

	function menuFunction(_type = 'none', _data) {
		if (_type === 'none')
			return;

		switch (_type) {
			case 'visible':
				setMenuVisible(!menuVisible);
				break;
			case 'profile':
				props.store.Main.setModal('profile', _data);
				setMenuVisible(false);
				break;
			case 'logout':
				props.store.User.logout();
				setMenuVisible(false);
				break;
			default:
				break;
		}
	}

	return (
		<HeaderComponent>
			<HeaderTitle>
			{
				namespace !== null && namespace !== undefined?
					<NamespaceSection>
						<NamespaceName>{namespace.name}</NamespaceName>
						<Icon name='info' width='20px' hoverColor={vars.COLOR_INFO} onClick={() => props.store.Main.setModal('namespace', namespace)}/>
						{
							namespace.namespace_type_id === 2 && projects.length > 0 ?
								null : <Icon name='add' width='20px' hoverColor={vars.COLOR_INFO} onClick={() => props.store.Main.setModal('project', { id: '', namespace_id: namespace.id})}/>
						}
					</NamespaceSection> :
					<AnimatedLogo visible={sidebarVisible}>
						<Logo width='100px' height='50px'/>
					</AnimatedLogo>
			}
			</HeaderTitle>
			<UserSection>
				<Avatar firstName={userData.first_name} lastName={userData.last_name} url={userData.profile_image_url} />
				<UserInfo>
					<span>{userData.first_name+' '+userData.last_name}</span>
					<UserEmail>{userData.email}</UserEmail>
				</UserInfo>
				<Icon name='menu' width='16px' color={vars.COLOR_TEXT_SECONDARY} hoverColor={vars.COLOR_TEXT_MAIN} onClick={() => menuFunction('visible')}/>
				<UserMenu visible={menuVisible}>
					<UserMenuItem onClick={() => menuFunction('profile', userData)}>Профиль</UserMenuItem>
					<UserMenuItem border={true} onClick={() => menuFunction('logout')}>Выйти</UserMenuItem>
				</UserMenu>
			</UserSection>
		</HeaderComponent>
	);
}

export default inject("store")(observer(Header)); 
