import SVG from '../components/icon';

export default function File (props) {
    return (
        <SVG
            width={props.width}
            height={props.height === undefined || props.height === null ? props.width : props.height}
            style={{margin: 'auto 0'}}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            {...props}
        >
            <g>
            <g>
            <path d="M437.333,128H330.667c-5.888,0-10.667-4.8-10.667-10.667V10.667C320,4.779,315.221,0,309.333,0H96
            C78.357,0,64,14.357,64,32v448c0,17.643,14.357,32,32,32h320c17.643,0,32-14.357,32-32V138.667
            C448,132.779,443.221,128,437.333,128z M426.667,480c0,5.867-4.779,10.667-10.667,10.667H96c-5.888,0-10.667-4.8-10.667-10.667V32
            c0-5.867,4.779-10.667,10.667-10.667h202.667v96c0,17.643,14.357,32,32,32h96V480z"/>
            </g>
            </g>
            <g>
            <g>
            <path d="M444.864,131.136l-128-128c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083l128,128
            c2.091,2.069,4.821,3.115,7.552,3.115c2.731,0,5.461-1.045,7.531-3.115C449.024,142.059,449.024,135.296,444.864,131.136z"/>
            </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </SVG>
    );
}