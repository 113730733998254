export const config = {
    api: {
        host_main: {
            development: "http://localhost:8100/",
            production: "https://tsvetkov.one:8100/"
        },
        host_andtask: {
            development: "http://localhost:8101/",
            production: "https://andtask.tsvetkov.one:8101/",
        },
        main: {
            sign_up: "api/auth/signup",
            sign_in: "api/auth/signin",
            get: "api/user",
            update: "api/user",
            change_profile_image: "api/changeprofileimage"
        },
        namespace_type: "api/namespacetypes",
        namespace: {
            getall: "api/namespaces",
            create: "api/namespace",
            update: "api/namespace",
            setFavorite: 'api/namespace/setFavorite',
            delete: "api/namespace/"
        },
        history_type: "api/historytypes",
        history: "api/histories",
        project: {
            getall: "api/projects",
            create: "api/project",
            update: "api/project",
            delete: "api/project/"
        },
        project_block: {
            getall: "api/projectblocks",
            create: "api/projectblock",
            update: "api/projectblock",
            delete: "api/projectblock/",
            reorder: "api/projectblock/reorder"
        },
        task: {
            getall: "api/tasks",
            create: "api/task",
            update: "api/task",
            delete: "api/task/",
            reorder: "api/task/reorder"
        },
        subtask: {
            getall: "api/subtasks",
            create: "api/subtask",
            update: "api/subtask",
            delete: "api/subtask/",
        }, 
        tag: {
            getall: "api/tags",
            create: "api/tag",
            update: "api/tag",
            delete: "api/tag/",
        }, 
        timepicker: {
            getall: "api/timepickers",
            create: "api/timepicker",
            update: "api/timepicker",
            delete: "api/timepicker/",
        },
        file: {
            getall: "api/files",
            upload: "api/file",
            delete: "api/file/"
        }
    }
}