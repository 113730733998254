// libraries, components, etc
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import Icon from '../../icons';
import * as vars from '../../constants';
import { useEffect, useState } from "react";
import { declension } from "../../utils";
import Schedule from "./schedule";

// styles
const Component = styled.div`
	flex-direction: row;
	gap: 2rem;
	display: flex;
	width: 100%;
	height: calc(100% - ${vars.SIZE_HEADER_HEIGHT});
	padding: 1rem;

	@media ${vars.DEVICE.mobileS} {
		flex-direction: column;
	}

	@media ${vars.DEVICE.laptopS} {
		flex-direction: row;
	}
`;
const Content = styled.div`
	flex: 1;
`;
const HistoryList = styled.div`
	flex-direction: column;
	gap: .5rem;
	display: flex;
	padding: .5rem;
`;
const Section = styled.div`
	flex: 1;
	border: 1px solid ${vars.COLOR_BORDER};
	border-radius: .25rem;
	min-width: 30%;
`;
const SectionName = styled.span`
	position: relative;
	justify-content: center;
	display: flex;
	border-bottom: 1px solid ${vars.COLOR_BORDER};
	border-radius: .25rem;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	width: 100%;
	padding: .25rem .5rem;
	background-color: ${vars.COLOR_WHITE};
	font-weight: 600;
	text-align: center;
`;
const TaskItem = styled.div`
	flex-direction: row;
	justify-content: space-between;
	display: flex;
	border: 1px solid ${props => props.expired ? vars.COLOR_DANGER : vars.COLOR_BORDER};
	border-radius: .25rem;
	padding: .25rem .5rem;
	background-color: ${vars.COLOR_WHITE};
	cursor: ${props => props.isTask ? 'pointer' : 'default'};

	&:hover {
		border: 1px solid ${vars.COLOR_PRIMARY};
	}
`;
const DueDate = styled.div`
	gap: .25rem;
	display: flex;
	margin: auto 0;
`;
const LastActions = styled.span`
	position: absolute;
	right: 0;
	padding: .25rem .5rem;
	cursor: pointer;
	font-size: smaller;
	text-align: end;
	color: lightgray;
`;

function Home(props) {
	const datePeriodEnd = new Date();
	datePeriodEnd.setDate(datePeriodEnd.getDate() + 7);
	datePeriodEnd.setHours(0,0,0,0);
	const todayDate = new Date().setHours(0,0,0,0);

	const [isHistoryView, setHistoryView] = useState(false);
	const [histories, setHistories] = useState([]);
	const [tasks, setTasks] = useState([]);
	const [todayTasks, setTodayTasks] = useState([]);
	const [taskExpireCount, setExpireCount] = useState(0);

	useEffect(() => {
		let tempHistories = props.store.History.histories || [];
		setHistories(tempHistories.slice(0,23));
	}, [props.store.History.histories]);
	
	useEffect(() => {
		let tempTasks = props.store.Task.tasks.filter(task => !task.is_done && datePeriodEnd >= new Date(task.end_due_date).setHours(0,0,0,0) && task.end_due_date !== null).slice(0,20);
		tempTasks.sort((a,b) => {
			return new Date(a.end_due_date).getTime() - new Date(b.end_due_date).getTime();
		});
		let tempTodayTasks = tempTasks.filter(task => todayDate == new Date(task.end_due_date).setHours(0,0,0,0));
		
		setTasks(tempTasks);
		setTodayTasks(tempTodayTasks);
		setExpireCount(tempTasks.length - tempTodayTasks.length);
	}, [props.store.Task.tasks]);

    return <Component>
		<Content>
			<h1>Привет, {props.store.User.userName}!👋</h1>
			{
				todayTasks.length === 0 && <h2>Похоже, у тебя сегодня выходной🥳</h2>
			}
			{
				todayTasks.length !== 0 && <h2>Сегодня тебе надо выполнить {todayTasks.length} {declension(todayTasks.length, ['задача', 'задачи', 'задач'])}</h2>
			}
			{
				taskExpireCount !== 0 && <h2>Но у тебя также есть еще {taskExpireCount} {declension(taskExpireCount, ['просроченная задача', 'просроченные задачи', 'просроченных задач'])}</h2>
			}
			{

			}
			<Section>
				<SectionName>{isHistoryView ? 'Последние действия' : 'Задачи'}
					<LastActions onClick={() => setHistoryView(!isHistoryView)}>
						{isHistoryView ? 'Задачи' : 'Последние действия'}
					</LastActions>
				</SectionName>
				<HistoryList>
				{
					isHistoryView && histories.map(history =>
						<TaskItem>
							<span>
							<b>{history.object_name !== undefined ? history.object_name : ''}</b>
							: {(history.history_type !== undefined ? history.history_type.name_ru : '')+' '+new Date(history.history_datetime).toLocaleDateString()}
							</span>
						</TaskItem>	
					)
				}
				{
					!isHistoryView && tasks.map(task => 
						<TaskItem onClick={() => props.store.Main.setModal('task', task)} isTask expired={todayDate > new Date(task.end_due_date).setHours(0,0,0,0)}>
							<span>{task.name}</span>
							<DueDate>
								<Icon name='due_date' width='16px'/>
								<span>{new Date(task.end_due_date).toLocaleDateString()}</span>
							</DueDate>
						</TaskItem>
					)
				}
				{
					!isHistoryView && tasks.length === 0 && <span style={{color: vars.COLOR_TEXT_SECONDARY}}>Пусто...</span>
				}
				</HistoryList>
			</Section>
        </Content>
		<Content>
			<h1>Твой план на день</h1>
			<Schedule tasks={todayTasks}/>
		</Content>
    </Component>
};

export default inject("store")(observer(Home));