import { inject, observer } from "mobx-react";
import { useState } from "react";
import styled from "styled-components";
import NamespaceModal from './namespace';
import ProfileModal from './profile';
import ProjectBlockModal from './project_block';
import ProjectModal from './project';
import TaskModal from './task';
import * as vars from '../../constants';

const Component = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    opacity: ${props => props.visible ? '1' : '0'};
    transition: ease-in 0.2s opacity, ease-in 0.2s visibility;
    width: 100vw; 
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
`;
const ModalComponent = styled.div`
    position: fixed;
    z-index: 101;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
    width: auto;
    height: auto;
    background-color: ${vars.COLOR_WHITE};

    @media ${vars.DEVICE.mobileS} {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    @media ${vars.DEVICE.laptopS} {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
    }
`;

function Modal(props) {
    const name = props.store.Main.modalName;
    const data = props.store.Main.modalData;

    return <Component visible={props.store.Main.modalVisible} onClick={null}>
        <ModalComponent visible={props.store.Main.modalVisible}>
        {
            name === 'namespace' && <NamespaceModal data={data}/>
        }
        {
            name === 'project' && <ProjectModal data={data}/>
        }
        {
            name === 'project_block' && <ProjectBlockModal data={data}/>
        }
        {
            name === 'task' && <TaskModal data={data}/>
        }
        {
            name === 'profile' && <ProfileModal data={data}/>
        }
        </ModalComponent>
    </Component>
}

export default inject("store")(observer(Modal)); 