// Libraries, components, etc. //
import axios from 'axios';
import { action, makeObservable, observable } from "mobx";
import { toast } from 'react-toastify';
import { config } from '../env';
import { getToken, setToken, removeToken } from '../utils';
import file from './file';
import history_type from './history_type';
import history from './history';
import namespace_type from './namespace_type';
import namespace from './namespace';
import project_block from './project_block';
import project from './project';
import subtask from './subtask';
import tag from './tag';
import task from './task';
import timepicker from './timepicker';

const NODE_ENV = process.env.NODE_ENV || 'production';

class User {
    token = '';
    userData = {};
    isSignIn = true;
    userName = 'незнакомец';

    constructor() {
        makeObservable(this, {
            token: observable,
            userData: observable,
            isSignIn: observable,
            signIn: action,
            signUp: action,
            setState: action,
            logout: action,
            uploadProfileImage: action,
            update: action,
            getUserName: action,
            userName: observable
        });

        this.loadData();

        this.signIn = this.signIn.bind(this);
        this.signUp = this.signUp.bind(this);
        this.setState = this.setState.bind(this);
        this.logout = this.logout.bind(this);
        this.uploadProfileImage = this.uploadProfileImage.bind(this);
        this.update = this.update.bind(this);
        this.getUserName = this.getUserName.bind(this);
    }

    loadData() {
        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'andtask';

        this.token = getToken();
        
        if (this.token !== '') {
            axios.defaults.headers.common['x-access-token'] = this.token;
            axios.get(config.api.host_main[NODE_ENV] + config.api.main.get)
            .then(result => {
                if (result.data.status) {
                    this.userData = result.data.data;
                    this.userName = result.data.data?.first_name || result.data.data?.user_name || 'незнакомец';

                    file.loadData();
                    history_type.loadData();
                    history.loadData();
                    namespace_type.loadData();
                    namespace.loadData();
                    project_block.loadData();
                    project.loadData();
                    subtask.loadData();
                    tag.loadData();
                    task.loadData();
                    timepicker.loadData();
                }
                else {
                    toast.error(result.data.text);
                    removeToken();
                }
            })
            .catch(error => {
                toast.error(error.response.data.text);
            })
        }
    }

    signIn(_data) {
        const data = {
            user_name: _data.user_name,
            password: _data.password
        };
        axios.post(config.api.host_main[NODE_ENV] + config.api.main.sign_in, data)
        .then(result => {
            if (result.data.status) {
                this.token = result.data.access_token;
                this.userData = result.data.data;
                this.userName = result.data.data?.first_name || result.data.data?.user_name || 'незнакомец';
                setToken(this.token);

                file.loadData();
                history_type.loadData();
                history.loadData();
                namespace_type.loadData();
                namespace.loadData();
                project_block.loadData();
                project.loadData();
                subtask.loadData();
                tag.loadData();
                task.loadData();
                timepicker.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    signUp(data) {
        return axios.post(config.api.host_main[NODE_ENV] + config.api.main.sign_up, data);
    }

    setState(_state) {
        this.isSignIn = _state;
    }

    logout() {
        this.token = '';
        removeToken();
    }

    uploadProfileImage(_file) {
        const data = new FormData();
        data.append('file', _file);

        axios.post(config.api.host_main[NODE_ENV] + config.api.main.change_profile_image, data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    update(_data) {
        axios.put(config.api.host_main[NODE_ENV] + config.api.main.update, _data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    getUserName() {
        return this.userData?.first_name || this.userData?.user_name || 'незнакомец';
    }
}

export default new User();