import { useRouteError } from "react-router-dom";
import styled from "styled-components";

const Component = styled.div`
	flex-direction: column;
	justify-content: center;
	display: flex;
	width: 100%;
	height: 100%;
	text-align: center;
`;

export default function ErrorPage(props) {
  const error = useRouteError();
  console.error(error);

  return (
    <Component>
      <h1>Oops!😥</h1>
      <p>К сожалению, произошла непредвиденная ошибка</p>
      <p>
        <i>{props.message || error.statusText || error.message}</i>
      </p>
    </Component>
  );
}