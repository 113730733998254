// Libraries, components, etc. //
import axios from 'axios';
import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import { toast } from 'react-toastify';
import {config} from '../env';
import { getToken } from '../utils';

const NODE_ENV = process.env.NODE_ENV || 'production';

class Task {
    token = '';
    tasks = [];

    constructor() {
        /*makeObservable(this, {
            token: observable,
            tasks: observable,
            create: action,
            update: action,
            delete: action,
            reorder: action
        });*/
        makeAutoObservable(this);

        this.token = getToken();
        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'andtask';
        axios.defaults.headers.common['x-access-token'] = this.token;
        this.loadData();

        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.reorder = this.reorder.bind(this);
    }

    loadData() {
        axios.get(config.api.host_andtask[NODE_ENV]+config.api.task.getall)
        .then(result => {
            if (result.data.status) {
                this.tasks = result.data.data;
            }
            else {
                toast.error(result.data.text);
            }
        })
    }

    async create(_data, _subtaskData, _tagData, _fileData, _store) {
        const data = {
            name: _data.name,
            is_done: _data.is_done,
            project_block_id: _data.project_block_id,
            description: _data.description,
            start_due_date: _data.start_due_date,
            start_due_time: _data.start_due_time,
            end_due_date: _data.end_due_date,
            end_due_time: _data.end_due_time,
            notification_due_date: _data.notification_due_date,
            notification_due_time: _data.notification_due_time,
            file_preview_id: _data.file_preview_id
        };

        axios.post(config.api.host_andtask[NODE_ENV] + config.api.task.create, data)
        .then(result => {
            if (result.data.status) {
                const task_id = result.data.data.task_id;

                _subtaskData.map(subtask => {
                    subtask.task_id = task_id;
                    _store.Subtask.create(subtask);
                });

                _tagData.map(tag => {
                    tag.task_id = task_id;
                    _store.Tag.create(tag);
                });

                _fileData.map(file => {
                    const data = {
                        id: file.id,
                        task_id: task_id,
                        file: file
                    };
                    _store.File.upload(data);
                })

                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    update(_data) {
        const data = {
            id: _data.id,
            name: _data.name,
            is_done: _data.is_done,
            description: _data.description,
            start_due_date: _data.start_due_date,
            start_due_time: _data.start_due_time,
            end_due_date: _data.end_due_date,
            end_due_time: _data.end_due_time,
            notification_due_date: _data.notification_due_date,
            notification_due_time: _data.notification_due_time,
            done_datetime: _data.done_datetime,
            file_preview_id: _data.file_preview_id
        };

        axios.put(config.api.host_andtask[NODE_ENV] + config.api.task.update, data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    delete(_id) {
        axios.delete(config.api.host_andtask[NODE_ENV] + config.api.task.delete + _id)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    reorder(_data) {
        const data = {
            id: _data.id,
            old_order: _data.old_order,
            new_order: _data.new_order,
            block_from: _data.block_from,
            block_to: _data.block_to
        }

        axios.post(config.api.host_andtask[NODE_ENV] + config.api.task.reorder, data)
        .then(result => {
            if (result.status) {
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }
}

export default new Task();