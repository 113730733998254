// Libraries, components, etc. //
import React, { useState } from 'react';
import styled from 'styled-components';
import * as vars from '../constants';

//#region Styles
const TextareaContainer = styled.div`
    position: relative; 
    margin-top: 0.7rem;
    min-height: 2.5rem;
`;
const TextareaLabel = styled.label`
    position: absolute;
    top: ${props => props.isFocused || props.value !== '' ? '0' : '10%'};
    left: 0;
    display: flex;
    transform: ${props => props.isFocused || props.value !== '' ? 'translateY(-50%)' : 'translateY(-10%)'};
    transform-origin: left top;
    transition: 0.1s ease-out;
    padding: 0 0.3rem;
    background: transparent;
    pointer-events: none;
    font-size: 0.9rem;
    color: ${props => getColor(props.isFocused, props.value !== '', true)};
`;
const TextareaObject = styled.textarea`
    transition: 0.1s ease-out;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    width: 100%;
    height: auto;
    padding: 1rem 0.7rem;
    background-color: transparent;
    color: ${props => getColor(props.isFocused, props.value !== '', true)};//${vars.COLOR_TEXT_MAIN};
    font-size: 1rem;
    outline: none;
`;
const Border = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    border-left: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-bottom: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-radius: .25rem;
    width: 100%;
    height: 100%;
    pointer-events: none;
`;
const TextareaLabelText = styled.span`
    width: 100%;
    padding: 0 0.3rem;
    font-size: ${props => props.isFocused || props.value !== '' ? '0.9rem' : '1rem'};
    color: transparent;
`;
const BorderStart = styled.div`
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    border-top: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    width: 0.5rem;
    height: 100%;
`;
const BorderLabel = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    transition: border-top 0.1s ease;
    margin-left: 0.5rem;
    border-top: ${props => props.isFocused || props.value !== '' ? '0' : '1px'} solid ${vars.COLOR_BORDER};
    height: 100%;
`;
const BorderEnd = styled.div`
    content: " ";
    position: relative;
    top: 0;
    border-top: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-right: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    height: 100%;
    width: 100%;
`;
//#endregion

function Textarea(props) {
    const [isFocused, setFocused] = useState(false);

    return(
        <TextareaContainer>
            <TextareaObject id={props.id} rows='3' type={props.type} onChange={props.onChange} value={props.value} isFocused={isFocused} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}/>
            <Border isFocused={isFocused} value={props.value}>
                <BorderStart isFocused={isFocused} value={props.value}/>
                <BorderLabel isFocused={isFocused} value={props.value}>
                <TextareaLabel isFocused={isFocused} value={props.value}>{props.label}</TextareaLabel>
                    <TextareaLabelText isFocused={isFocused} value={props.value}>{props.label}</TextareaLabelText>
                </BorderLabel>
                <BorderEnd isFocused={isFocused} value={props.value}/>
            </Border>
        </TextareaContainer>
    )
}

function getColor(_isFocused = false, _hasValue = false, _isText = false) {
    let color = '';

    if (_isFocused) {
        color = _hasValue ? vars.COLOR_INFO : vars.COLOR_DANGER;
    }
    else {
        color = _hasValue ? vars.COLOR_SECONDARY : _isText ? vars.COLOR_TEXT_SECONDARY : vars.COLOR_BORDER;
    }

    return color;
}

export default Textarea;