// Libraries, components, etc. //
import { Provider } from "mobx-react";
import { toJS } from "mobx";
import React from 'react';
import ReactDOM from 'react-dom';
import { Routes, Route, Switch, createBrowserRouter,  RouterProvider, createRoutesFromElements } from "react-router-dom";
import { createGlobalStyle } from 'styled-components';
import App from './App';
import File from './store/file';
import History from './store/history';
import HistoryType from './store/history_type';
import Main from './store/main';
import Namespace from './store/namespace';
import NamespaceType from './store/namespace_type';
import Project from './store/project';
import ProjectBlock from './store/project_block';
import Subtask from './store/subtask';
import Tag from './store/tag';
import Task from './store/task';
import Timepicker from './store/timepicker';
import User from './store/user';
import * as vars from './constants';
import Home from './pages/home';
import NamespaceP from './pages/namespace';
import ErrorPage from "./pages/error";
import axios from "axios";
import { getToken } from './utils';
const GlobalStyle = createGlobalStyle`
	body {
		margin: 0;
		width: 100vw;
    	height: 100vh;
		font-family: 'Nunito', sans-serif;
		background-color: ${vars.COLOR_BACKGROUND};

		*, ::before, ::after {
			box-sizing: border-box;
		}
	}

	::-webkit-scrollbar {
		width: .5rem;
    }

	::-webkit-scrollbar-thumb {
		border-radius: .25rem;
		border-width: 1px 1px 1px 2px;
		border-color: rgb(119, 119, 119);
		background-color: rgb(170, 170, 170);
	}

	::-webkit-scrollbar-track {
    	border-width: 0px;
	}
`;

axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'andtask';
        axios.defaults.headers.common['x-access-token'] = getToken();

const store = {
	File,
	History,
	HistoryType,
	Main,
	Namespace,
	NamespaceType,
	Project,
	ProjectBlock,
	Subtask,
	Tag,
	Task,
	Timepicker,
	User
}

const routes = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App/>} errorElement={<ErrorPage/>}>
			<Route index element={<Home/>}/>
			<Route path='/namespace/:id' element={<NamespaceP/>} caseSensitive={false} errorElement={<ErrorPage/>}/>
			<Route path="/namespace/" element={<ErrorPage message='Раздел не найден'/>} />
        </Route>
    )
);

ReactDOM.createRoot(document.getElementById("root")).render(
	<Provider store={store}>
		<GlobalStyle />
		<RouterProvider router={routes} />
	</Provider>
);