import SVG from '../components/icon';

export default function FileXLS (props) {
    return (
        <SVG
            width={props.width}
            height={props.height === undefined || props.height === null ? props.width : props.height}
            style={{margin: 'auto 0'}}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            {...props}
        >
			<g>
			<g>
			<path d="M494.479,138.557L364.04,3.018C362.183,1.09,359.621,0,356.945,0h-194.41c-21.757,0-39.458,17.694-39.458,39.442v137.789
			H44.29c-16.278,0-29.521,13.239-29.521,29.513v147.744C14.769,370.761,28.012,384,44.29,384h78.787v88.627
			c0,21.71,17.701,39.373,39.458,39.373h295.238c21.757,0,39.458-17.653,39.458-39.351V145.385
			C497.231,142.839,496.244,140.392,494.479,138.557z M359.385,26.581l107.079,111.265H359.385V26.581z M44.29,364.308
			c-5.42,0-9.828-4.405-9.828-9.82V206.744c0-5.415,4.409-9.821,9.828-9.821h265.881c5.419,0,9.829,4.406,9.829,9.821v147.744
			c0,5.415-4.41,9.82-9.829,9.82H44.29z M477.538,472.649c0,10.84-8.867,19.659-19.766,19.659H162.535
			c-10.899,0-19.766-8.828-19.766-19.68V384h167.401c16.279,0,29.522-13.239,29.522-29.512V206.744
			c0-16.274-13.243-29.513-29.522-29.513H142.769V39.442c0-10.891,8.867-19.75,19.766-19.75h177.157v128
			c0,5.438,4.409,9.846,9.846,9.846h128V472.649z"/>
			</g>
			</g>
			<g>
			<g>
			<path d="M284.645,286.819c-2.25-3.636-5.366-6.501-9.346-8.598c-3.981-2.095-10.124-4.123-18.432-6.085
			c-8.308-1.962-13.538-3.847-15.692-5.654c-1.694-1.423-2.54-3.137-2.54-5.135c0-2.193,0.903-3.944,2.713-5.251
			c2.808-2.038,6.693-3.057,11.653-3.057c4.807,0,8.414,0.953,10.818,2.855c2.403,1.903,3.97,5.029,4.701,9.376l17.076-0.751
			c-0.268-7.769-3.084-13.981-8.45-18.633c-5.367-4.655-13.356-6.981-23.97-6.981c-6.501,0-12.049,0.979-16.645,2.942
			c-4.596,1.962-8.116,4.817-10.558,8.566c-2.442,3.751-3.665,7.781-3.665,12.089c0,6.69,2.597,12.364,7.791,17.019
			c3.692,3.306,10.114,6.095,19.269,8.364c7.114,1.77,11.673,3.001,13.671,3.692c2.925,1.039,4.972,2.26,6.144,3.663
			c1.174,1.406,1.76,3.106,1.76,5.108c0,3.114-1.394,5.836-4.182,8.162c-2.789,2.326-6.932,3.49-12.433,3.49
			c-5.192,0-9.317-1.307-12.374-3.924c-3.057-2.614-5.085-6.71-6.087-12.288l-16.616,1.615c1.115,9.462,4.539,16.665,10.27,21.607
			c5.731,4.94,13.942,7.412,24.635,7.412c7.345,0,13.482-1.029,18.405-3.087c4.923-2.055,8.728-5.201,11.421-9.433
			c2.693-4.229,4.04-8.768,4.04-13.615C288.02,294.942,286.895,290.452,284.645,286.819z"/>
			</g>
			</g>
			<g>
			<g>
			<polygon points="168.943,310.673 168.943,241.039 151.864,241.039 151.864,324.923 211.405,324.923 211.405,310.673 		"/>
			</g>
			</g>
			<g>
			<g>
			<polygon points="113.73,281.423 140.039,240.347 120.251,240.347 103.636,267.52 86.673,240.347 66.713,240.347 92.903,280.788 
			64,324.923 84.48,324.923 103.289,295.672 122.038,324.923 142.634,324.923 		"/>
			</g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
        </SVG>
    );
}