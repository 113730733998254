import styled from "styled-components";
import * as vars from '../../constants';

const Component = styled.div`
    flex-direction: row;
	justify-content: space-between;
	display: flex;
	margin: 0 1rem;
	padding-top: .5rem;
`;
const Title = styled.span`
	font-size: 0.75rem;
	text-transform: uppercase;
	color: ${vars.COLOR_WHITE};
`;

export default function Group(props) {
    return <Component>
        <Title>{props.title}</Title>
        { props.children }
    </Component>
}