import React from 'react';
import Add from './add';
import Delete from './delete';
import Done from './done';
import DueDate from './due_date';
import Edit from './edit';
import Favorite from './favorite';
import FavoriteFill from './favorite_fill';
import File from './file';
import FileDOC from './file_doc';
import FilePDF from './file_png';
import FilePPT from './file_ppt';
import FileTXT from './file_txt';
import FileXLS from './file_xls';
import FileZIP from './file_zip';
import Hide from './hide';
import Info from './info';
import Logo from './logo';
import Menu from './menu';
import Preview from './preview';
import Save from './save';
import Show from './show';

const Icon = (props) => {
    const name = props.name || '';
    const width = props.width || '16px';
    switch(name) {
        case 'add': 
            return <Add width={width} {...props}/>;
        case 'delete':
            return <Delete width={width} {...props}/>;
        case 'logo':
            return <Logo width={width} {...props}/>;
        case 'info':
            return <Info width={width} {...props}/>
        case 'favorite':
            return <Favorite width={width} {...props}/>;
        case 'favorite_fill': 
            return <FavoriteFill width={width} {...props}/>;
        case 'hide':
            return <Hide width={width} {...props}/>;
        case 'show':
            return <Show width={width} {...props}/>;
        case 'done':
            return <Done width={width} {...props}/>;
        case 'file':
            return <File width={width} {...props}/>;
        case 'file_pdf':
            return <FilePDF width={width} {...props}/>;
        case 'file_doc':
            return <FileDOC width={width} {...props}/>;
        case 'file_xls':
            return <FileXLS width={width} {...props}/>;
        case 'file_ppt':
            return <FilePPT width={width} {...props}/>;
        case 'file_zip':
            return <FileZIP width={width} {...props}/>;
        case 'file_txt':
            return <FileTXT width={width} {...props}/>;
        case 'preview':
            return <Preview width={width} {...props}/>;
        case 'edit':
            return <Edit width={width} {...props}/>;
        case 'save':
            return <Save width={width} {...props}/>;
        case 'menu':
            return <Menu width={width} {...props}/>;
        case 'due_date':
            return <DueDate width={width} {...props}/>;
        default:
            return null;
    }
}

export default Icon;