// libs
import { 
    inject, 
    observer
} from "mobx-react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
import Auth from './pages/auth';
import Header from './components/header';
import Modal from './components/modal';
import Sidebar from './components/sidebar';
// etc
import { COLORS, SIZE_HEADER_HEIGHT } from "./constants";

const Page = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: ${COLORS.BACKGROUND};
`;
const Content = styled.div`
	flex: 1;
    display: flex;
	overflow: auto;
    height: calc(100% - ${SIZE_HEADER_HEIGHT});
`;

function App(props) {
    const token = props.store.User.token;

    if (token === '') {
        return <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={10}/>
            <Auth/>
        </>;
    }

    return (
        <Page>
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={10}/>
            <Modal />
            <Header />
            <Sidebar/>
            <Content>
                <Outlet />
            </Content>
        </Page>
    );
}

export default inject("store")(observer(App));