import styled from "styled-components";
import * as vars from '../../constants';

const Component = styled.div`
	flex: 1;
	grid-column-gap: 1rem;
	grid-template-rows: 
		[tracks] auto
		[time-06] 1fr
		[time-07] 1fr
		[time-08] 1fr
		[time-09] 1fr
		[time-10] 1fr
		[time-11] 1fr
		[time-12] 1fr
		[time-13] 1fr
		[time-14] 1fr
		[time-15] 1fr
		[time-16] 1fr
		[time-17] 1fr
		[time-18] 1fr
		[time-19] 1fr
		[time-20] 1fr
		[time-21] 1fr
		[time-22] 1fr
		[time-23] 1fr;
	grid-template-columns:
		[times] 4em
		[track-1-start] 1fr
		[track-1-end track-2-start] 1fr
		[track-2-end track-3-start] 1fr
		[track-3-end];
	display: grid;
`;
const TimeSlot = styled.h3`
	grid-row: ${props => props.track};
`;
const Session = styled.div`
	grid-column: ${props => props.track};
	grid-row: ${props => props.rowtrack};
	margin: .5rem 0;
	border: 1px solid ${vars.COLOR_BORDER};
	border-radius: .25rem;
	padding: .25rem .5rem;
	background-color: ${vars.COLOR_WHITE};
`;
const Line = styled.div`
	content: ' ';
	grid-column: track-1-start / track-3-end; 
	grid-row: ${props => props.track};
	border-top: 1px solid ${vars.COLOR_BORDER};
`

const hoursArray = [...Array(24).keys()].slice(6);

export default function Schedule(props) {
    let taskTrackMatrix = [...Array(24)].fill(Array.from([]));
    props.tasks.map(task => {
        if (task.start_due_time !== null || task.end_due_date !== null) {
            let startTime = parseInt(task.start_due_time) || 0;
            let endTime = parseInt(task.end_due_time) || 0;
            
            for (let time of taskTrackMatrix.keys()) {
                if (startTime <= time && time <= endTime) {
                    let taskIdsArray = Array.from(taskTrackMatrix[time]);
                    taskIdsArray.push(task.id)
                    taskTrackMatrix[time] = taskIdsArray;
                }
            }
        }
    });

    function getTrack(id) {
		let maxIdx = -1;
		let canFill = true;

		taskTrackMatrix.map(item => {
			let idx = item.indexOf(id);

			if (idx != -1 && idx != maxIdx && item.length > 1) {
				canFill = false;
			}

			if (idx > maxIdx) {
				maxIdx = idx;
			}
		})

		if (canFill) {
			return 'track-1-start / track-3-end';
		}

		if (maxIdx != -1) {
			return 'track-'+(maxIdx+1)+'-start / track-'+(maxIdx+1)+'-end';
		}

		return 'track-1-start / track-1-end';
	}

	function getRow(item) {
		let startTime = parseInt(item.start_due_time) || 6;
		let endTime = (parseInt(item.end_due_time) + 1) || 24;
		
		return 'time-'+(''+startTime).padStart(2, '0')+' / '+'time-'+(''+endTime).padStart(2, '0');
	}

    return <Component>
    {
        hoursArray.map(item => <>
			<TimeSlot track={'time-'+(''+item).padStart(2,'0')}>{(''+item).padStart(2,'0')}:00</TimeSlot>
        	<Line track={'time-'+(''+item).padStart(2,'0')}/>
        </>
		)
    }
    {
        props.tasks && props.tasks.map(task => 
            <Session track={getTrack(task.id)} rowtrack={getRow(task)}>
                <span>{task.name}</span>
            </Session>	
        )
    }
    </Component>
}