import styled from "styled-components";
import * as vars from '../../constants';
import Icon from '../../icons';

const Component = styled.button`
    flex-direction: row;
    display: flex;
	margin: 0 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
    background: white;
	color: ${vars.COLOR_TEXT_SECONDARY};
	outline: none;

	&:hover {
		color: ${vars.COLOR_SECONDARY};
		font-weight: 400;
	}
`;
const Title = styled.span`
	flex: 1;
	padding: 0.3rem;
	cursor: pointer;
	text-align: center;
`;
const IconGroup = styled.div`
    flex-direction: row;
	gap: 0.25rem;
	display: flex;
	margin: auto 0;
	border-left: 1px solid ${vars.COLOR_BORDER};
	height: 100%;
	padding-left: 0.25rem;
`;

export default function MenuItem(props) {
    return <Component {...props}>
        <Title>{props.name}</Title>
        {
            props.clickInfo && props.clickFavorite && <IconGroup>
                <Icon onClick={props.clickInfo} name='info' width='15px' color={vars.COLOR_TEXT_SECONDARY} hoverColor={vars.COLOR_SECONDARY}/>
                <Icon onClick={props.clickFavorite} name={props.isFavorite ? 'favorite_fill' : 'favorite'} width='15px' color={vars.COLOR_TEXT_SECONDARY} hoverColor={vars.COLOR_SECONDARY}/>
            </IconGroup>
        }
    </Component>
}