import SVG from '../components/icon';

export default function FileZIP (props) {
    return (
        <SVG
            width={props.width}
            height={props.height === undefined || props.height === null ? props.width : props.height}
            style={{margin: 'auto 0'}}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            {...props}
        >
			<g>
			<g>
			<path d="M494.479,138.557L364.04,3.018C362.183,1.09,359.621,0,356.945,0h-194.41c-21.757,0-39.458,17.694-39.458,39.442v137.789
			H44.29c-16.278,0-29.521,13.239-29.521,29.513v147.744C14.769,370.761,28.012,384,44.29,384h78.787v88.627
			c0,21.71,17.701,39.373,39.458,39.373h295.238c21.757,0,39.458-17.653,39.458-39.351V145.385
			C497.231,142.839,496.244,140.392,494.479,138.557z M359.385,26.581l107.079,111.265H359.385V26.581z M44.29,364.308
			c-5.42,0-9.828-4.405-9.828-9.82V206.744c0-5.415,4.409-9.821,9.828-9.821h265.881c5.419,0,9.829,4.406,9.829,9.821v147.744
			c0,5.415-4.41,9.82-9.829,9.82H44.29z M477.538,472.649c0,10.84-8.867,19.659-19.766,19.659H162.535
			c-10.899,0-19.766-8.828-19.766-19.68V384h167.401c16.279,0,29.522-13.239,29.522-29.512V206.744
			c0-16.274-13.243-29.513-29.522-29.513H142.769V39.442c0-10.891,8.867-19.75,19.766-19.75h177.157v128
			c0,5.438,4.409,9.846,9.846,9.846h128V472.649z"/>
			</g>
			</g>
			<g>
			<g>
			<rect x="173.785" y="240.345" width="17.076" height="84.578"/>
			</g>
			</g>
			<g>
			<g>
			<polygon points="115.405,310.673 161.73,253.615 161.73,240.347 99.828,240.347 99.828,254.653 139.23,254.653 94.809,309.519 
			94.809,324.923 163.52,324.923 163.52,310.673 		"/>
			</g>
			</g>
			<g>
			<g>
			<path d="M267.02,249.895c-3.272-4.251-7.328-7.01-12.175-8.281c-3.153-0.847-9.922-1.267-20.308-1.267h-27.405v84.576h17.078
			v-31.904h0h11.133c7.732,0,13.634-0.404,17.713-1.211c2.998-0.655,5.952-1.992,8.854-4.01c2.905-2.019,5.3-4.798,7.183-8.337
			c1.886-3.537,2.828-7.904,2.828-13.096C271.923,259.636,270.289,254.144,267.02,249.895z M252.394,273.174
			c-1.288,1.883-3.067,3.269-5.337,4.153c-2.27,0.883-6.769,1.326-13.499,1.326h-9.346v-24h8.249c6.156,0,10.252,0.192,12.29,0.576
			c2.769,0.502,5.056,1.75,6.865,3.751c1.807,1.999,2.71,4.539,2.71,7.616C254.326,269.095,253.684,271.288,252.394,273.174z"/>
			</g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
        </SVG>
    );
}