import SVG from '../components/icon';

export default function FileTXT (props) {
    return (
        <SVG
            width={props.width}
            height={props.height === undefined || props.height === null ? props.width : props.height}
            style={{margin: 'auto 0'}}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            {...props}
        >
			<g>
			<g>
			<path d="M494.478,138.557L364.036,3.018C362.18,1.09,359.62,0,356.942,0H162.534c-21.757,0-39.457,17.694-39.457,39.442v137.789
			H44.289c-16.276,0-29.519,13.239-29.519,29.513v147.744c0,16.273,13.243,29.512,29.519,29.512h78.788v88.627
			c0,21.71,17.699,39.373,39.457,39.373h295.24c21.757,0,39.457-17.653,39.457-39.351V145.385
			C497.231,142.839,496.245,140.392,494.478,138.557z M359.385,26.584l107.079,111.263H359.385V26.584z M44.289,364.308
			c-5.418,0-9.827-4.405-9.827-9.82V206.744c0-5.415,4.409-9.821,9.827-9.821h265.882c5.421,0,9.829,4.406,9.829,9.821v147.744
			c0,5.415-4.409,9.82-9.829,9.82H44.289z M477.538,472.649c0,10.84-8.865,19.659-19.764,19.659h-295.24
			c-10.899,0-19.764-8.828-19.764-19.68V384h167.401c16.279,0,29.522-13.239,29.522-29.512V206.744
			c0-16.274-13.243-29.513-29.522-29.513H142.769V39.442c0-10.891,8.865-19.75,19.764-19.75h177.159v128
			c0,5.438,4.409,9.846,9.846,9.846h128V472.649z"/>
			</g>
			</g>
			<g>
			<g>
			<polygon points="227.365,240.347 227.365,254.653 252.46,254.653 252.46,324.923 269.538,324.923 269.538,254.653 
			294.577,254.653 294.577,240.347 		"/>
			</g>
			</g>
			<g>
			<g>
			<polygon points="76.383,240.347 76.383,254.653 101.482,254.653 101.482,324.923 118.558,324.923 118.558,254.653 
			143.596,254.653 143.596,240.347 		"/>
			</g>
			</g>
			<g>
			<g>
			<polygon points="195.749,281.423 222.058,240.347 202.269,240.347 185.654,267.52 168.691,240.347 148.731,240.347 
			174.922,280.788 146.018,324.923 166.501,324.923 185.307,295.672 204.056,324.923 224.655,324.923 		"/>
			</g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
        </SVG>
    );
}