// Libraries, components, etc. //
import axios from 'axios';
import { action, makeObservable, observable } from "mobx";
import { toast } from 'react-toastify';
import { config } from '../env';
import { getToken } from '../utils';
import task from './task';

const NODE_ENV = process.env.NODE_ENV || 'production';

class File {
    files = [];
    
    constructor() {
        makeObservable(this, {
            files: observable,
            upload: action,
            delete: action,
            loadData: action
        });

        this.upload = this.upload.bind(this);
        this.delete = this.delete.bind(this);
        
        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'andtask';
        axios.defaults.headers.common['x-access-token'] = getToken();
        this.loadData();
    }

    loadData() {
        axios.get(config.api.host_andtask[NODE_ENV]+config.api.file.getall)
        .then(result => {
            if (result.data.status) {
                this.files = result.data.data;
            }
            else {
                toast.error(result.data.text);
            }
        })
    }

    upload(_data) {
        const data = new FormData();
        data.append('id', _data.id);
        data.append('task_id', _data.task_id);
        data.append('file', _data.file)

        axios.post(config.api.host_andtask[NODE_ENV] + config.api.file.upload, data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
                task.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    async uploadAsync(data) {
        const data1 = new FormData();
        data.append('id', data1.id);
        data.append('task_id', data1.task_id);
        data.append('file', data1.file)

        try {
            const result = await axios.post(config.api.host_andtask[NODE_ENV] + config.api.file.upload, data1);
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
                return true;
            } 
            else {
                toast.error(result.data.text);
                return false;
            }
                
        }
        catch(err) {
            toast.error(err);
            return false;
        }
    }

    delete(_id) {
        axios.delete(config.api.host_andtask[NODE_ENV] + config.api.file.delete + _id)
        .then(result => {
            if (result.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }
}

export default new File();