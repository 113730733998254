// libs
import {
    inject,
    observer
} from "mobx-react";
import styled, {keyframes} from "styled-components";
import Button from '../components/button';
import Input from '../components/input';
import * as vars from '../constants';
import { useState } from "react";
import { toast } from 'react-toastify';

const GradientAnimation = keyframes`
  	0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
`;
const Component = styled.div`
    display: flex;
	animation: ${GradientAnimation} 15s ease infinite;
	width: 100vw;
	height: 100vh;
	background: ${vars.gradient};
    background-size: 400% 400%;
`;
const Modal = styled.div`
    flex-direction: column;
    justify-content: center;
	display: flex;
	transition: all .5s ease;
	margin: auto;
	border-radius: 0.5rem;
	min-width: 600px;
	height: 600px;
	background-color: white;
    box-shadow: rgb(0 0 0 / 57%) 0px 3px 10px -8px;

    @media ${vars.DEVICE.mobileS} {
		min-width: 300px;
        height: 500px;
	}

    @media ${vars.DEVICE.mobileM} {
		min-width: 330px;
	}

    @media ${vars.DEVICE.mobileL} {
		min-width: 350px;
	}

	@media ${vars.DEVICE.laptopS} {
		min-width: 600px;
        height: 600px;
	}
`;
const Title = styled.span`
	padding: 0.5rem;
	font-size: 2.5rem;
	font-weight: 200;
`;
const Form = styled.div`
	flex-direction: column;
	justify-content: center;
	gap: 0.5rem;
	display: flex;
	margin: auto;
	width: 50%;
	padding: 0.5rem;
    text-align: center;

    @media ${vars.DEVICE.mobileS} {
		width: 100%;
	}

    @media ${vars.DEVICE.mobileM} {
		width: 80%;
	}


	@media ${vars.DEVICE.laptopS} {
		width: 50%;
	}
`;
const GradientTextButton = styled.a`
	color: ${vars.COLOR_TEXT_SECONDARY};
	user-select: none;

	&:hover {
		background: linear-gradient(-45deg, rgb(64%, 29%, 49%, 1), rgb(14%, 65%, 84%, 1));
    	-webkit-background-clip: text;
    	-webkit-text-fill-color: transparent;
		cursor: pointer;
	}
`;
const AdditionalFields = styled.div`
	flex-direction: column;
	gap: 0.5rem;
	display: flex;
	overflow: hidden;
	transition: all .75s ease;
	opacity: ${props => props.isSignUp ? '1' : '0'};
	max-height: ${props => props.isSignUp ? '300px' : '0px'};
    padding-bottom: .5rem;
`;

function Auth(props) {

    const [isSignUp, setSignUp] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    function checkMainFields() {
        if (userName === null || userName === '') {
			toast.error('Логин не может быть пустым.');
			return false;
		}

		if (password === null || password === '') {
			toast.error('Пароль не может быть пустым.');
			return false;
		}

        return true;
    }

    function checkAdditionalFields() {
        if (email === null || email === '') {
            toast.error('E-mail не может быть пустым.');
            return false;
        }

        if (firstName === null || firstName === '') {
            toast.error('Имя не может быть пустым.');
            return false;
        }

        if (lastName === null || lastName === '') {
            toast.error('Фамилия не может быть пустой.');
            return false;
        }

        return true;
    }

    function signIn() {
        if (!checkMainFields()) {
            return;
        }

        const data = {
			user_name: userName,
			password: password,
			email: email,
			first_name: firstName,
			last_name: lastName
		};
        props.store.User.signIn(data);
    }

    function signUp() {
        if (!checkMainFields() && !checkAdditionalFields()) {
            return;
        }

        const data = {
			user_name: userName,
			password: password,
			email: email,
			first_name: firstName,
			last_name: lastName
		};
        props.store.User.signUp(data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                setSignUp(false);
            }
            else {
                toast.error(result.data.text);
            }  
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    return <Component>
        <Modal>
            <Form>
                <Title>{isSignUp ? 'Регистрация' : 'Авторизация'}</Title>
                <Input type='text' label='Логин' value={userName} onChange={(event) => setUserName(event.target.value)}/>
                <Input type='password' label='Пароль' value={password} onChange={(event) => setPassword(event.target.value)}/>
                <AdditionalFields isSignUp={isSignUp}>
                    <Input type='email' label='Email' value={email} onChange={(event) => setEmail(event.target.value)}/>
                    <Input type='text' label='Имя' value={firstName} onChange={(event) => setFirstName(event.target.value)}/>
                    <Input type='text' label='Фамилия' value={lastName} onChange={(event) => setLastName(event.target.value)}/>
                </AdditionalFields>
                <Button onClick={() => isSignUp ? signUp() : signIn()}>{isSignUp ? 'Зарегистрироваться' : 'Войти'}</Button>
                <span>Или все-таки <GradientTextButton onClick={() => setSignUp(!isSignUp)}>{isSignUp ? 'зарегистрирован?' : 'регистрация?'}</GradientTextButton></span>
            </Form>
        </Modal>
    </Component>

}

export default inject("store")(observer(Auth));