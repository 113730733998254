import styled, {keyframes} from 'styled-components';
import * as vars from '../../constants';

const Component = styled.div`
position: absolute;
	top: 13px;
	left: calc(20vw + 13px);
	width: 24px;
	cursor: pointer;

	@media ${vars.DEVICE.mobileS} {
		left: ${props => props.visible ? 'calc(100vw - 37px)' : 'calc(100vw + 13px)'};
	}

	@media ${vars.DEVICE.laptopS} {
		left: calc(20vw + 13px);
	}
`;
const hamburgerTop = keyframes`
	0% {
		top: 0;
		transform: rotate(0);
	}
	50% {
		top: 9px;
		transform: rotate(0);
	}
	100% {
		top: 9px;
		transform: rotate(45deg);
	}
`;
const hamburgerTop2 = keyframes`
	0% {
		top: 9px;
		transform: rotate(45deg);
	}
	50% {
		top: 9px;
		transform: rotate(0deg);
	}
	100% {
		top: 0;
		transform: rotate(0deg);
	}
`;
const hamburgerBottom = keyframes`
	0% {
		bottom: 0;
		transform: rotate(0);
	}
	50% {
		bottom: 9px;
		transform: rotate(0);
	}
	100% {
		bottom: 9px;
		transform: rotate(135deg);
	}
`;
const hamburgerBottom2 = keyframes`
	0% {
		bottom: 9px;
		transform: rotate(135deg);
	}
	50% {
		bottom: 9px;
		transform: rotate(0);
	}
	100% {
		bottom: 0;
		transform: rotate(0);
	}
`;
const hamburgerScale = keyframes`
	50% {
		transform: scale(0);
	}
	100% {
		transform: scale(0);
	}
`;
const hamburgerScale2 = keyframes`
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
`;
const HamburgerMenuLine = styled.span`
	position: relative;
	display: block;
	transition: all .3s;
	border-radius: 0.25rem;
	width: 100%;
	height: 3px;
	background: linear-gradient(-45deg, #f7bba9, #d1a5be, #92d3eb, #92ebd5);
	background-size: 200% 200%;
	
	@media ${vars.DEVICE.mobileS} {
		background: ${props => !props.isActive ? 'linear-gradient(-45deg, #f7bba9, #d1a5be, #92d3eb, #92ebd5)' : 'white'};
		background-size: 100% 100%;
	}

	@media ${vars.DEVICE.tablet} {
		background: linear-gradient(-45deg, #f7bba9, #d1a5be, #92d3eb, #92ebd5);
	background-size: 200% 200%;
	}

	& + & {
		margin-top: 6px;
	}

	&:nth-child(1) {
		animation: ease 0.7s ${props => props.isActive ? hamburgerTop : hamburgerTop2} forwards;
	}

	&:nth-child(2) {
		animation: ease 0.7s ${props => props.isActive ? hamburgerScale : hamburgerScale2} forwards;
	}

	&:nth-child(3) {
		animation: ease 0.7s ${props => props.isActive ? hamburgerBottom : hamburgerBottom2} forwards;
	}
`;

export default function HamburgerButton(props) {
    return <Component visible={props.visible} onClick={props.onClick}>
        <HamburgerMenuLine isActive={props.visible}/>
        <HamburgerMenuLine isActive={props.visible}/>
        <HamburgerMenuLine isActive={props.visible}/>
    </Component>
}