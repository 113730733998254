// Libraries, components, etc. //
import React, { useState } from 'react';
import styled from 'styled-components';
import * as vars from '../constants';

//#region Styles
const InputContainer = styled.div`
    position: relative; 
    margin-top: 0.7rem;
    height: 2.5rem;
    width: 100%;
`;
const InputLabel = styled.label`
    position: absolute;
    top: ${props => props.isFocused || props.value !== '' ? '0' : '50%'};
    left: 0;
    display: flex;
    transform: ${props => props.isFocused || props.value !== '' ? 'translateY(-50%)' : 'translateY(-50%)'};
    transform-origin: left top;
    transition: 0.1s ease-out;
    padding: 0 0.3rem;
    background: transparent;
    pointer-events: none;
    font-size: 0.9rem;
    color: ${props => getColor(props.isFocused, props.value !== '', true)};
    white-space: nowrap;
`;
const InputObject = styled.input`
    z-index: 1;
    transition: 0.1s ease-out;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    width: 100%;
    height: 2.5rem;
    padding: 1rem 0.7rem;
    background-color: transparent;
    color: ${props => getColor(props.isFocused, props.value !== '', true)};
    font-size: 1rem;
    outline: none;
    pointer-events: auto;
`;
const Border = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    border-bottom: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    pointer-events: none;
`;
const InputLabelText = styled.span`
    width: 100%;
    padding: 0 0.3rem;
    font-size: ${props => props.isFocused || props.value !== '' ? '0.9rem' : '1rem'};
    color: transparent;
    white-space: nowrap;
`;
const BorderStart = styled.div`
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    border-top: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-left: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    width: 0.5rem;
    height: 2.5rem;
    pointer-events: none;
`;
const BorderLabel = styled.div`
    position: relative;
    flex: 1 1;
    display: flex;
    transition: border-top 0.1s ease;
    margin-left: 0.5rem;
    border-top: ${props => props.isFocused || props.value !== '' ? '0' : '1px'} solid ${vars.COLOR_BORDER};
    height: 100%;
    width: 100%;
    pointer-events: none;
`;
const BorderEnd = styled.div`
    content: " ";
    position: relative;
    top: 0;
    border-top: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-right: 1px solid ${props => getColor(props.isFocused, props.value !== '')};
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    height: 2.5rem;
    width: 100%;
    pointer-events: none;
`;
//#endregion

function Input(props) {
    const [isFocused, setFocused] = useState(false);

    return(
        <InputContainer>
            <InputObject id={props.id} type={props.type} onChange={props.onChange} value={props.value} isFocused={isFocused} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}/>
            <Border isFocused={isFocused} value={props.value}>
                <BorderStart isFocused={isFocused} value={props.value}/>
                <BorderLabel isFocused={isFocused} value={props.value}>
                <InputLabel isFocused={isFocused} value={props.value}>{props.label}</InputLabel>
                    <InputLabelText isFocused={isFocused} value={props.value}>{props.label}</InputLabelText>
                </BorderLabel>
                <BorderEnd isFocused={isFocused} value={props.value}/>
            </Border>
        </InputContainer>
    )
}

function getColor(_isFocused = false, _hasValue = false, _isText = false) {
    let color = '';

    if (_isFocused) {
        color = _hasValue ? vars.COLOR_INFO : vars.COLOR_DANGER;
    }
    else {
        color = _hasValue ? vars.COLOR_SECONDARY : _isText ? vars.COLOR_TEXT_SECONDARY : vars.COLOR_BORDER;
    }

    return color;
}

export default Input;