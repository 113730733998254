// Libraries, components, etc. //
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Button from '../button';
import Input from '../input';
import Textarea from '../textarea';
import * as vars from '../../constants';

//#region Styles
const ModalComponent = styled.div`
    width: 100%;
    height: 100%;

    @media ${vars.DEVICE.mobileS} {
        width: 100vw;
    }

    @media ${vars.DEVICE.laptopS} {
        width: 100%;
    }
`;
const Header = styled.div`
    flex-direction: row;
    display: flex;
    border-bottom: 1px solid ${vars.COLOR_BORDER};
    width: 100%;
    height: 40px;
    text-align: center;
`;
const Title = styled.span`
    margin: auto 1rem;
    width: 100%;
    font-size: 1rem;
    text-align: center;
`;
const FormRow = styled.div`
    flex-direction: row;
    display: flex;

    @media ${vars.DEVICE.mobileS} {
        flex-direction: column;
    }

    @media ${vars.DEVICE.laptopS} {
        flex-direction: row;
    }
`;
const Form = styled.div`
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    display: ${props => props.hidden ? 'none' : 'flex'};
    padding: .5rem 1rem;
`;
const ButtonRow = styled.div`
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    display: flex;
    padding-bottom: 1rem;
`;
const SectionName = styled.span`
    display: inline-block;
    font-weight: 600;
    color: ${vars.COLOR_TEXT_MAIN};
`;
const HistoryItem = styled.span`
    display: inline-block;
    width: 100%;
    font-size: 0.75rem;
    color: ${vars.COLOR_TEXT_SECONDARY};
`;
const HistoryList = styled.div`
    flex-direction: column;
    display: flex;
`;
//#endregion

function ModalProjectBlock(props) {
	const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [projectId, setProjectId] = useState('');
    const [isHideDone, setHideDone] = useState(false);
    const [histories, setHistories] = useState([]);
    useEffect(() => {
        setProjectId(props.data.project_id);
        if (props.data.id !== '') {
            setId(props.data.id);
            setName(props.data.name);
            setDescription(props.data.description);
            setHideDone(props.data.is_hide_done);
        }
        let hist = toJS(props.store.History.histories).filter(history => history.type === 'project_block' & history.object_id === props.data.id).slice(0,5)
        setHistories(hist);
    }, [props.data, props.store.History.histories])
    //const histories = (this.props.store.History.histories.filter(history => history.type === 'project_block' & history.object_id === id)).slice(0,5);

    function projectBlockFunction(type = 'none') {
        if (type === 'none')
            return;

        if (name === '' || name === null) {
            toast.error('Название не заполнено');
            return;
        }

        const data = {
            id: id,
            name: name,
            description: description,
            project_id: projectId,
            is_hide_done: isHideDone
        }

        switch (type) {
            case 'add':
                props.store.ProjectBlock.create(data);
                break;
            case 'update':
                props.store.ProjectBlock.update(data);
                break;
            case 'delete':
                props.store.ProjectBlock.delete(data.id);
                break;
            default:
                break;
        }

        props.store.Main.hideModal();
    }

    return (
        <ModalComponent>
            <Header>
                <Title>{id === '' ? 'Добавить блок' : 'Изменить блок'}</Title>
                <Button type='close' onClick={() => props.store.Main.hideModal()}/>
            </Header>
            <FormRow>
                <Form>
                    <Input type='text' id='name' label='Название' value={name} onChange={(event) => setName(event.target.value)}/>
                    <Textarea type='text' id='description' label='Описание' value={description} onChange={(event) => setDescription(event.target.value)}/>
                </Form>
                <Form hidden={id === ''}>
                    <HistoryList>
                    <SectionName>Последние действия</SectionName>
                    {
                        histories.map((history, index) =>
                            <HistoryItem key={index}>{history.history_type.name_ru+' '+new Date(history.history_datetime).toLocaleDateString()}</HistoryItem>
                        )
                    }
                    </HistoryList>
                </Form>
            </FormRow>
            <ButtonRow>
                {
                    id !== '' && <Button type='danger' size='0.9rem' onClick={() => projectBlockFunction('delete')}>Удалить</Button>
                }
                <Button type='success' size='0.9rem' onClick={() => projectBlockFunction(id === '' ? 'add' : 'update')}>{id === '' ? 'Добавить' : 'Сохранить'}</Button>
            </ButtonRow>
        </ModalComponent>
    );
}

export default inject("store")(observer(ModalProjectBlock)); 
