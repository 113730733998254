// Libraries, components, etc. //
import { action, makeObservable, observable } from "mobx";

class Main {
    sidebarVisible = true;
    modalVisible = false;
    modalName = '';
    modalData = null;
    currentNamespace = null;

    constructor() {
        makeObservable(this, {
            sidebarVisible: observable,
            modalVisible: observable,
            modalName: observable,
            currentNamespace: observable,
            setSidebarVisible: action,
            setModal: action,
            hideModal: action,
            setNamespace: action
        });

        this.setSidebarVisible = this.setSidebarVisible.bind(this);
        this.setModal = this.setModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.setNamespace = this.setNamespace.bind(this);
    }

    setSidebarVisible(_state) {
        this.sidebarVisible = _state;
    }

    setModal(_name, _data) {
        this.modalVisible = true;
        this.modalName = _name;
        this.modalData = _data;
    }

    hideModal() {
        this.modalVisible = false;
        this.modalName = '';
        this.modalData = null;
    }

    setNamespace(_namespace) {
        this.currentNamespace = _namespace;
    }
}

export default new Main();