// Libraries, components, etc. //
import axios from 'axios';
import { action, makeObservable, observable } from "mobx";
import { toast } from 'react-toastify';
import { config } from '../env';
import { getToken } from '../utils';

const NODE_ENV = process.env.NODE_ENV || 'production';

class ProjectBlock {
    token = '';
    projectBlocks = [];

    constructor() {
        makeObservable(this, {
            token: observable,
            projectBlocks: observable,
            create: action,
            update: action,
            delete: action,
            reorder: action
        });

        this.token = getToken();
        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'andtask';
        axios.defaults.headers.common['x-access-token'] = this.token;
        this.loadData();

        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.reorder = this.reorder.bind(this);
    }

    loadData() {
        axios.get(config.api.host_andtask[NODE_ENV]+config.api.project_block.getall)
        .then(result => {
            if (result.data.status) {
                this.projectBlocks = result.data.data;
            }
            else {
                toast.error(result.data.text);
            }
        })
    }

    create(_data) {
        const data = {
            name: _data.name,
            is_hide_done: _data.is_hide_done,
            project_id: _data.project_id,
            description: _data.description
        };

        axios.post(config.api.host_andtask[NODE_ENV] + config.api.project_block.create, data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    update(_data) {
        const data = {
            id: _data.id,
            name: _data.name,
            is_hide_done: _data.is_hide_done,
            description: _data.description
        };

        axios.put(config.api.host_andtask[NODE_ENV] + config.api.project_block.update, data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    delete(_id) {
        axios.delete(config.api.host_andtask[NODE_ENV] + config.api.project_block.delete + _id)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }

    reorder(_data) {
        const data = {
            id: _data.id,
            old_order: _data.old_order,
            new_order: _data.new_order,
            project_id: _data.project_id
        }

        axios.post(config.api.host_andtask[NODE_ENV] + config.api.project_block.reorder, data)
        .then(result => {
            if (result.data.status) {
                toast.success(result.data.text);
                this.loadData();
            }
            else
                toast.error(result.data.text);
        })
        .catch(error => {
            toast.error(error.response.data.text);
        })
    }
}

export default new ProjectBlock();