import { inject, observer } from "mobx-react";
import styled, {keyframes} from 'styled-components';
import Icon from '../../icons';
import * as vars from '../../constants';
import { useNavigate } from "react-router-dom";
import Logo  from "../../icons/logo";
import MenuItem from "./menu_item";
import Group from "./group";
import HamburgerButton from "./hamburger_button";

const gradient = keyframes`
  	0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
`;
const Component = styled.div`
    position: fixed;
	z-index: 50;
    top: 0;
    left: 0;
	flex-direction: column;
	gap: .5rem;
	display: flex;
	transform: ${props => props.visible ? 'translateX(0)' : 'translateX(-20vw)'};
    animation: ${gradient} 15s ease infinite;
	transition: transform 0.3s ease-in;
    border-right: 1px solid ${vars.COLOR_BORDER};
	width: 20vw;
	height: 100%;
	background: linear-gradient(-45deg, #f7bba9, #d1a5be, #92d3eb, #92ebd5);
    background-size: 400% 400%;

	@media ${vars.DEVICE.mobileS} {
		transform: ${props => props.visible ? 'translateX(0)' : 'translateX(-100vw)'};
		width: calc(100vw);
	}

	@media ${vars.DEVICE.laptopS} {
		transform: ${props => props.visible ? 'translateX(0)' : 'translateX(-20vw)'};
		width: 20vw;
	}
`;
const LogoSection = styled.div`
    margin: 0 auto;
	margin-bottom: 3rem;
`;
const ItemList = styled.div`
	flex: ${props => props.isFavorite ? '1, 0' : '1'};
	flex-direction: column;
	gap: 0.5rem;
	display: flex;
	overflow-y: auto;
`;

const Copyright = styled.span`
	display: inline-block;
	transition: color 0.2s ease-in;
	padding: 0.5rem;
	cursor: pointer;
	text-align: center;
	color: transparent;
	user-select: none;

	&:hover {
		color: white;
	}
`;

function Sidebar(props) {
    const navigate = useNavigate();
    let hamburgerActiveState = false;
    const sidebarVisible = props.store.Main.sidebarVisible;
    const namespaces = props.store.Namespace.namespaces;
    const namespacesFavorite = props.store.Namespace.namespaces.filter(namespace => namespace.is_favorite === true);

    function setMenuItem(path, data) {
        navigate(path);
        props.store.Main.setSidebarVisible(false);
		props.store.Main.setNamespace(data);
    }

    function setNamespaceFavorite(event, id, state) {
		event.stopPropagation();
		props.store.Namespace.setFavorite(id, state);
	}

    return <Component visible={sidebarVisible}>
			<HamburgerButton visible={sidebarVisible} onClick={() => props.store.Main.setSidebarVisible(!sidebarVisible)}/>
            <LogoSection>
                <Logo color="white"/>
            </LogoSection>
            <MenuItem name='Обзор' onClick={() => setMenuItem('/', null)}/>
            {
                namespacesFavorite.length > 0 && <>
					<Group title='Избранное'/>
					<ItemList isFavorite={true}>
					{
						namespacesFavorite.map((namespace, index) => 
							<MenuItem key={index} onClick={() => setMenuItem('/namespace/'+namespace.id, namespace)} name={namespace.name}/>	
						)
					}
					</ItemList>
				</>
            }
            <Group title='Разделы'>
                <Icon name='add' width='16px' color='white' hoverColor={vars.COLOR_TEXT_SECONDARY} onClick={() => props.store.Main.setModal('namespace', null)}/>
            </Group>
            <ItemList>
            {
                namespaces.map((namespace, index) => 
                    <MenuItem name={namespace.name} key={index} onClick={() => setMenuItem('/namespace/'+namespace.id, namespace)}
					clickInfo={(event) => {event.preventDefault(); event.stopPropagation(); props.store.Main.setModal('namespace', namespace)}}
					clickFavorite={(event) => setNamespaceFavorite(event, namespace.id, !namespace.is_favorite)}
					isFavorite={namespace.is_favorite}
					/>)
            }
            </ItemList>
            <Copyright>by rt</Copyright>
    </Component>
}
export default inject("store")(observer(Sidebar)); 