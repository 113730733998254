import {
    inject,
    observer
} from "mobx-react";
import { toJS } from "mobx";
import React, { 
    useEffect,
    useState
} from 'react';
import {
    DragDropContext,
    Droppable,
    Draggable
} from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useLoaderData, useParams } from 'react-router-dom';
import Icon from '../../icons';
import ProjectBlock from './project_block';
import * as vars from '../../constants';

const Component = styled.div`
    flex-direction: column;
	gap: 1rem;
	overflow-y: auto;
	display: flex;
	width: 100vw;
	height: 100%;
	padding: 1rem;
	background: ${vars.COLOR_BACKGROUND};
`
const ProjectSection = styled.div`
	flex-direction: column;
	display: flex;
	box-shadow: rgb(0 0 0 / 57%) 0px 3px 10px -8px;
	border: 1px solid ${vars.COLOR_BORDER};
	border-radius: .25rem;
	width: 100%;
	max-height: calc(100vh - ${vars.SIZE_HEADER_HEIGHT} - 2rem);
	background-color: ${vars.COLOR_WHITE};
`;
const ProjectHeader = styled.div`
	flex-direction: column;
	display: flex;
	border-bottom: 1px solid ${vars.COLOR_BORDER};
	width: 100%;
	padding: .25rem 1rem;
`;
const ProjectFooter = styled.div`
	border-top: 1px solid ${vars.COLOR_BORDER};
	height: 1rem;
`;
const ProjectHeaderName = styled.div`
	flex-direction: row;
	display: flex;
	gap: .25rem;
	font-size: 1.1rem;
	font-weight: 600;
	color: ${vars.COLOR_TEXT_MAIN};
`;
const ProjectDescription = styled.span`
	font-size: 0.75rem;
	color: ${vars.COLOR_TEXT_SECONDARY};
`;
const ProjectBlocksSection = styled.div`
	flex-direction: row;
	gap: 1rem;
	display: flex;
	overflow: auto;
	padding: .5rem 1rem;
	background-color: ${vars.COLOR_BACKGROUND};
`;
const ProjectBlockItem = styled.div`
`;

function Namespace(props) {
    const { id } = useParams();
    const [projects, setProjects] = useState([]);
    const [projectBlocks, setProjectBlocks] = useState([]);

    useEffect(() => {
        setProjects(toJS(props.store.Project.projects).filter(project => project.namespace_id === id));
        setProjectBlocks(toJS(props.store.ProjectBlock.projectBlocks));
    }, [id, props.store.Project.projects, props.store.ProjectBlock.projectBlocks])

    function setProjectFavorite(_project) {
        const data = {
            id: _project.id,
            name: _project.name,
            description: _project.description,
            is_favorite: !_project.is_favorite
        }

        props.store.Project.update(data);
    }

    function onDragEnd(result) {
        if (result.destination === null)
            return;

        console.log(result);
        let data = null;
        switch (result.type) {
            case 'block':
                data = {
                    id: result.draggableId,
                    old_order: result.source.index,
                    new_order: result.destination.index,
                    project_id: result.source.droppableId
                }
                
                props.store.ProjectBlock.reorder(data);
                break;
            case 'task':
                data = {
                    id: result.draggableId,
                    old_order: result.source.index,
                    new_order: result.destination.index,
                    block_from: result.source.droppableId,
                    block_to: result.destination.droppableId
                }
                
                props.store.Task.reorder(data);
                break;
            default:
                break;
        }
    }
    
    return <Component>
    {
        projects !== null && projects.map(project => 
            <ProjectSection>
                <ProjectHeader>
                    <ProjectHeaderName>
                        <span>{project.name}</span>
                        <Icon name='info' onClick={() => props.store.Main.setModal('project', project)}/>
                        <Icon name={project.is_favorite ? 'favorite_fill' : 'favorite'} onClick={() => setProjectFavorite(project)}/>
                        <Icon name='add' onClick={() => props.store.Main.setModal('project_block', { id: '', project_id: project.id})}/>
                    </ProjectHeaderName>
                    {
                        project.description && <ProjectDescription>{project.description}</ProjectDescription>
                    }
                </ProjectHeader>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={project.id} type="block" direction='horizontal'>{(provided, snapshot) => (
                        <ProjectBlocksSection ref={provided.innerRef} {...provided.droppableProps}>
                        {
                            projectBlocks.filter(projectBlock => projectBlock.project_id === project.id).map((projectBlock, index) =>
                                <Draggable key={projectBlock.id} draggableId={projectBlock.id} index={index}>
                                {(provided1, snapshot) => (
                                    <ProjectBlockItem ref={provided1.innerRef} {...provided1.draggableProps} {...provided1.dragHandleProps}>
                                        <ProjectBlock data={projectBlock}/>
                                    </ProjectBlockItem>
                                )}
                                </Draggable>
                            )
                        }
                        {provided.placeholder}
                        </ProjectBlocksSection>)}
                    </Droppable>
                </DragDropContext>
                <ProjectFooter />
            </ProjectSection>
        )
    }
    </Component>
}

export default inject("store")(observer(Namespace)); 