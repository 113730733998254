import SVG from '../components/icon';

export default function DueDate (props) {
    return (
        <SVG
            width={props.width}
            height={props.height === undefined || props.height === null ? props.width : props.height}
            style={{margin: 'auto 0'}}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            {...props}
        >
			<g>
			<g>
			<g>
			<path d="M492,352c11.046,0,20-8.954,20-20V120c0-44.112-35.888-80-80-80h-26V20c0-11.046-8.954-20-20-20
			c-11.046,0-20,8.954-20,20v20h-91V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v20h-90V20c0-11.046-8.954-20-20-20
			s-20,8.954-20,20v20H80C35.888,40,0,75.888,0,120v312c0,44.112,35.888,80,80,80h352c44.112,0,80-35.888,80-80
			c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20c0,22.056-17.944,40-40,40H80c-22.056,0-40-17.944-40-40V120
			c0-22.056,17.944-40,40-40h25v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h90v20c0,11.046,8.954,20,20,20
			c11.046,0,20-8.954,20-20V80h91v20c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80h26c22.056,0,40,17.944,40,40v212
			C472,343.046,480.954,352,492,352z"/>
			<path d="M344.738,209.358l-98.581,137.7c-2.692,3.122-6.066,3.796-7.874,3.916c-1.862,0.13-5.473-0.115-8.571-3.063
			l-63.82-61.281c-7.971-7.651-20.631-7.393-28.278,0.574c-7.65,7.967-7.394,20.628,0.573,28.278l63.884,61.341
			c9.568,9.108,22.34,14.175,35.479,14.175c1.129,0,2.261-0.037,3.393-0.113c14.314-0.954,27.679-7.915,36.666-19.097
			c0.232-0.29,0.456-0.585,0.673-0.887l98.981-138.26c6.43-8.981,4.361-21.475-4.62-27.904
			C363.659,198.308,351.166,200.376,344.738,209.358z"/>
			</g>
			</g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
        </SVG>
    );
}