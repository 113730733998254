// Libraries, components, etc. //
import axios from 'axios';
import { makeAutoObservable } from "mobx";
import { toast } from 'react-toastify';
import { config } from '../env';

const NODE_ENV = process.env.NODE_ENV || 'production';

class HistoryType {
    historyTypes = [];

    constructor() {
        makeAutoObservable(this);

        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'andtask';
        this.loadData();
    }

    loadData() {
        axios.get(config.api.host_andtask[NODE_ENV]+config.api.history_type)
        .then(result => {
            if (result.data.status) {
                this.historyTypes = result.data.data;
            }
            else {
                toast.error(result.data.text);
            }
        })
    }
}

export default new HistoryType();