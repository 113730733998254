// Libraries, components, etc. //
import axios from 'axios';
import { makeObservable, observable } from "mobx";
import { toast } from 'react-toastify';
import {config} from '../env';
import { getToken } from '../utils';

const NODE_ENV = process.env.NODE_ENV || 'production';

class Histories {
    token = '';
    histories = null;

    constructor() {
        makeObservable(this, {
            histories: observable
        });

        this.token = getToken();
        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'andtask';
        axios.defaults.headers.common['x-access-token'] = this.token;
        this.loadData();
    }

    loadData() {
        axios.get(config.api.host_andtask[NODE_ENV]+config.api.history)
        .then(result => {
            if (result.status) {
                this.histories = result.data.data;
            }
            else {
                toast.error(result.data.text);
            }
        })
    }
}

export default new Histories();