import styled from 'styled-components';
import { COLORS } from '../constants';

const SVGStyle = styled.svg`
    transition: fill 0.2s ease-in;
    fill: ${props => props.color};
    cursor: pointer;

    &:hover {
        fill: ${props => props.hoverColor};
    }
`;

export default function Icon (props) {
    return <SVGStyle color={props.color || COLORS.TEXT_MAIN} hoverColor={props.hoverColor || COLORS.INFO} {...props}>{props.children}</SVGStyle>
}